import * as THREE from 'three';
import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import { useGLTFLoader, Text } from 'drei';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';
import Overlay from '../../components/util/Overlay';
import { Reflector } from '../Reflector/Reflector';

// function Carla(props) {
//   const group = useRef();
//   const { nodes, materials } = useGLTFLoader('/carla-draco.glb', true);

//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group rotation={[-Math.PI / 2, 0, 0]} scale={[0.03, 0.03, 0.03]}>
//         <group rotation={[Math.PI / 2, 0, 0]}>
//           <group rotation={[-Math.PI / 2, 0, 0]} scale={[100, 100, 100]}>
//             <mesh
//               material={materials.black}
//               geometry={nodes.Baarijakkara_LOW__0.geometry}
//               position={[0, -0.13, 0]}
//               scale={[0.94, 0.94, 0.94]}
//             />
//           </group>
//         </group>
//       </group>
//       <mesh
//         material={materials.black}
//         geometry={
//           nodes.rp_carla_rigged_001_geo_rp_carla_rigged_001_mat_0.geometry
//         }
//         scale={[0.04, 0.04, 0.04]}
//       />
//     </group>
//   );
// }

function Ground() {
  const { scene } = useThree();
  // const [floor, normal] = useLoader(THREE.TextureLoader, ['/SurfaceImperfections003_1K_var1.jpg', '/SurfaceImperfections003_1K_Normal.jpg']);
  const geometry = new THREE.CircleGeometry(40, 64);
  const groundMirror = new Reflector(geometry, {
    clipBias: 0.003,
    textureWidth: window.innerWidth * window.devicePixelRatio,
    textureHeight: window.innerHeight * window.devicePixelRatio,
    color: 0xff1111,
  });
  groundMirror.position.y = -1.5;
  groundMirror.rotateX(-Math.PI / 2);
  scene.add(groundMirror);

  return null;
}

// const Cube = ({ position }) => {
//   const light1Ref = useRef();
//   const light2Ref = useRef();
//   const light3Ref = useRef();
//   const light4Ref = useRef();

//   useFrame(() => {
//     const time = Date.now() * 0.001;
//     light1Ref.current.position.x = Math.sin(time * 0.7) * 1;
//     light1Ref.current.position.y = Math.cos(time * 0.5) * 0.5;
//     light1Ref.current.position.z = Math.cos(time * 0.3) * 1.5;

//     light2Ref.current.position.x = Math.sin(time * 0.3) * 2;
//     light2Ref.current.position.y = Math.cos(time * 0.5) * 1;
//     light2Ref.current.position.z = Math.cos(time * 0.7) * 3;

//     light3Ref.current.position.x = Math.sin(time * 0.3) * 2;
//     light3Ref.current.position.y = Math.cos(time * 0.5) * 0.5;
//     light3Ref.current.position.z = Math.cos(time * 0.7) * 1;

//     light4Ref.current.position.x = Math.sin(time * 0.3) * 4;
//     light4Ref.current.position.y = Math.cos(time * 0.5) * 1.25;
//     light4Ref.current.position.z = Math.cos(time * 0.7) * 2;
//   });

//   return (
//     <>
//       <pointLight
//         args={[0xff0040, 1, 0]}
//         ref={light1Ref}
//         position={[-6, 2, 18]}
//       >
//         <mesh>
//           <sphereGeometry args={[0.05, 16, 8]} />
//           <meshBasicMaterial color={0xff0040} />
//         </mesh>
//       </pointLight>
//       <pointLight args={[0x0040ff, 1, 0]} ref={light2Ref}>
//         <mesh>
//           <sphereGeometry args={[0.05, 16, 8]} />
//           <meshBasicMaterial color={0x0040ff} />
//         </mesh>
//       </pointLight>
//       <pointLight args={[0x80ff80, 1, 0]} ref={light3Ref}>
//         <mesh>
//           <sphereGeometry args={[0.05, 16, 8]} />
//           <meshBasicMaterial color={0x80ff80} />
//         </mesh>
//       </pointLight>
//       <pointLight args={[0xffaa00, 1, 0]} ref={light4Ref}>
//         <mesh>
//           <sphereGeometry args={[0.05, 16, 8]} />
//           <meshBasicMaterial color={0xffaa00} />
//         </mesh>
//       </pointLight>
//     </>
//   );
// };

function VideoText({ title, position, fontSize, clicked, ...props }) {
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src: '/woman.mp4',
      crossOrigin: 'Anonymous',
      loop: true,
      muted: true,
    })
  );
  useEffect(() => void (clicked && video.play()), [video, clicked]);
  return (
    <Text
      font="/Inter-Bold.woff"
      fontSize={fontSize || 1}
      letterSpacing={-0.06}
      position={position}
      textAlign="right"
      {...props}
    >
      {title}
      <meshBasicMaterial toneMapped={false}>
        <videoTexture
          attach="map"
          args={[video]}
          encoding={THREE.sRGBEncoding}
        />
      </meshBasicMaterial>
    </Text>
  );
}

function Intro({ start, set }) {
  const [vec] = useState(() => new THREE.Vector3());
  useEffect(() => setTimeout(() => set(true), 500), [set]);
  return useFrame((state) => {
    if (start) {
      state.camera.position.lerp(
        vec.set(state.mouse.x * 15, 3 + state.mouse.y * 20, 22),
        0.3
      );
      state.camera.lookAt(0, 0, 0);
    }
  });
}

export default function Mahsa() {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  useEffect(() => {
    initGA();
    PageView();
  });
  return (
    <>
      <Helmet>
        <title>Remember the name</title>
      </Helmet>
      <Canvas
        concurrent
        gl={{ alpha: false }}
        pixelRatio={[1, 1.5]}
        camera={{ position: [0, -1, 100], fov: 15 }}
      >
        <color attach="background" args={['black']} />
        {/* <fog attach="fog" args={['black', 15, 20]} /> */}
        <Suspense fallback={null}>
          <group position={[0, 0, 0]}>
            <group position={[1, -1, 0]}>
              <VideoText
                {...store}
                position={[0, 2.3, -2]}
                title="woman"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 1.3, -2]} title="life" />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
            <group position={[6, -1, 0]}>
              <VideoText
                {...store}
                position={[0, 2.3, -2]}
                title="woman"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 1.3, -2]} title="life" />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
            <group position={[-4, -1, 0]}>
              <VideoText {...store} position={[0, 2.3, -2]} title="woman" />
              <VideoText
                {...store}
                position={[0, 1.3, -2]}
                title="life"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
          </group>
          <group position={[0, 0, 0]}>
            <group position={[1, -1, 0]}>
              <VideoText
                {...store}
                position={[0, 2.3, -2]}
                title="woman"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 1.3, -2]} title="life" />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
            <group position={[6, -1, 0]}>
              <VideoText
                {...store}
                position={[0, 2.3, -2]}
                title="woman"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 1.3, -2]} title="life" />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
            <group position={[-4, -1, 0]}>
              <VideoText {...store} position={[0, 2.3, -2]} title="woman" />
              <VideoText
                {...store}
                position={[0, 1.3, -2]}
                title="life"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
          </group>
          <group position={[0, 3, 0]}>
            <group position={[1, -1, 0]}>
              <VideoText
                {...store}
                position={[0, 2.3, -2]}
                title="woman"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 1.3, -2]} title="life" />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
            <group position={[6, -1, 0]}>
              <VideoText
                {...store}
                position={[0, 2.3, -2]}
                title="woman"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 1.3, -2]} title="life" />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
            <group position={[-4, -1, 0]}>
              <VideoText {...store} position={[0, 2.3, -2]} title="woman" />
              <VideoText
                {...store}
                position={[0, 1.3, -2]}
                title="life"
                fontSize={1.05}
              />
              <VideoText {...store} position={[0, 0.3, -2]} title="freedom" />
            </group>
          </group>
          <group position={[-3, -1, 6]}>
            <VideoText {...store} position={[0, 2.3, -2]} title="woman" />
            <VideoText {...store} position={[0, 1.3, -2]} title="LIFE" />
            <VideoText
              {...store}
              position={[0, 0.3, -2]}
              title="freedom"
              fontSize={1.05}
            />
          </group>
          <group position={[3, -1, 6]}>
            <VideoText {...store} position={[0, 2.3, -2]} title="woman" />
            <VideoText {...store} position={[0, 1.3, -2]} title="LIFE" />
            <VideoText
              {...store}
              position={[0, 0.3, -2]}
              title="freedom"
              fontSize={1.05}
            />
          </group>
          <Intro start={ready && clicked} set={setReady} />
          <Ground />
          {/* <SkyBox /> */}
          {/* <Cube position={[2, 1, 0]} /> */}
        </Suspense>

        <ambientLight intensity={0.5} />
        <spotLight position={[0, 10, 0]} intensity={0.3} />
        <directionalLight position={[-20, 0, -10]} intensity={0.7} />
      </Canvas>
      <Overlay {...store}>
        <div onClick={() => ready && setClicked(true)}>
          {!ready ? 'Are you there ...' : 'click to continue'}
        </div>
        {/* <span className="audio-note">
          <span style={{ color: 'greenyellow' }}>!important</span>
          <br />
          ***** An Audio track will play by clicking above *****
        </span> */}
      </Overlay>
      <a id="model-credit" href="https://www.youtube.com/watch?v=M1v37uV6vik">
        video credit: Sevdaliza
      </a>
    </>
  );
}
