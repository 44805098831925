import React, { Suspense, useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { OrbitControls, useGLTFLoader, Loader } from 'drei';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';

import Overlay from './Overlay';
import './index.css';

const Cube = ({ sNumber }) => {
  const cubeRef = useRef();
  const { camera } = useThree();
  const [vec] = useState(() => new THREE.Vector3());

  useFrame((state) => {
    if (sNumber > 0) {
      state.camera.position.lerp(
        vec.set(0, 2 - sNumber * 4, 10 - sNumber * 10),
        0.22
      );
      state.camera.lookAt(0, 0, 0);
      cubeRef.current.rotation.x = Math.sin(sNumber * 10);
      cubeRef.current.rotation.y = Math.cos(sNumber * 10);
    }
  });

  return (
    <mesh ref={cubeRef}>
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
};

const Shadows = () => {
  useEffect(() => {
    initGA();
    PageView();
  });

  const overlay = useRef();
  const caption = useRef();
  const scroll = useRef(0);

  const [sNumber, setSnumber] = useState(0);

  useEffect(() => {
    console.log(sNumber);
  }, [sNumber]);

  const setCanvasBg = (n) => {
    if (n < 0.37) {
      return '#badcf2';
    }

    if (n >= 0.37 && n < 0.73) {
      return '#e2bccc';
    }

    return '#e6f4c8';
  };

  return (
    <>
      <Helmet>
        <title>----</title>
      </Helmet>
      <Canvas
        style={{ background: '#131516' }}
        camera={{ position: [0, 2, 10], fov: 75, far: 500 }}
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({
            offsetX: clientX,
            offsetY: clientY,
          }),
        }}
      >
        <Cube sNumber={sNumber} />

        <ambientLight intensity={1} />
        <directionalLight intensity={0.6} />
        <spotLight
          castShadow
          intensity={0.89}
          angle={0.7}
          penumbra={1}
          position={[0, 3, 1]}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-bias={-0.0001}
        />
      </Canvas>
      <Overlay
        ref={overlay}
        caption={caption}
        scroll={scroll}
        setSnumber={setSnumber}
      />
    </>
  );
};

export default Shadows;
