import React, { useEffect, useRef, useState, Suspense } from "react";
import * as THREE from "three";
import { Canvas, useFrame } from "react-three-fiber";
import { OrbitControls, useGLTFLoader, Loader } from "drei";
import useStore from "./store";

// const vec = new THREE.Vector3();
// const obj = new THREE.Object3D();
// const red = new THREE.Color("#100B7F");
const blue = new THREE.Color("#1F15FD");

function Intro() {
  // const clicked = useStore(state => state.clicked);
  const api = useStore(state => state.api);
  useEffect(() => api.loaded(), [api]);

  return null;
}

function Face(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTFLoader(
    "music/face/scene.gltf",
    true
  );

  const [mixer] = useState(() => new THREE.AnimationMixer());
  const { drums, snare, synth } = useStore(state => state.audio);
  // const track = useStore(state => state.track);

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [
    animations,
    mixer
  ]);
  useFrame((state, delta) => {
    mixer.update(0.1 * drums.gain);
    group.current.rotation.set(
      (snare.avg * snare.gain) / 200,
      (drums.avg * drums.gain) / 111,
      (synth.avg * synth.gain) / 80
    );

    materials["01_-_Default"].color
      .copy(blue)
      .multiplyScalar((synth.avg * (drums.gain || 1)) / 30);
  });

  return (
    <group ref={group} {...props} dispose={null} scale={[1, 1, 1]}>
      <group rotation={[-Math.PI / 2, 0, 0]} position={[0, -20, -250]}>
        <group position={[0, 0, 11.59]} rotation={[-0.27, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <primitive object={nodes._rootJoint} />
            <skinnedMesh
              name="0"
              geometry={nodes["0"].geometry}
              material={materials["02_-_Default"]}
              skeleton={nodes["0"].skeleton}
              morphTargetDictionary={nodes["0"].morphTargetDictionary}
              morphTargetInfluences={nodes["0"].morphTargetInfluences}
            />
            <skinnedMesh
              name="1"
              geometry={nodes["1"].geometry}
              material={materials["01_-_Default"]}
              skeleton={nodes["1"].skeleton}
              morphTargetDictionary={nodes["1"].morphTargetDictionary}
              morphTargetInfluences={nodes["1"].morphTargetInfluences}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

const Hand = props => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTFLoader(
    "music/hand/scene.gltf",
    true
  );

  // const time = useRef(0);
  const [mixer] = useState(() => new THREE.AnimationMixer());
  const { drums, snare, synth } = useStore(state => state.audio);
  // const track = useStore(state => state.track);

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [
    animations,
    mixer
  ]);
  useFrame((state, delta) => {
    mixer.update(0.06 * drums.gain);
    group.current.rotation.set(
      (snare.avg * snare.gain) / 100,
      (drums.avg * drums.gain) / 50,
      -(synth.avg * synth.gain) / 80
    );
    materials.lambert1.color
      .copy(blue)
      .multiplyScalar((synth.avg * (drums.gain || 1)) / 30);
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI, 0, 0]}>
        <group rotation={[-Math.PI, 0, Math.PI]}>
          <primitive object={nodes._rootJoint} />
          <skinnedMesh
            geometry={nodes.HandToMaya_BASE_0_lambert1_0.geometry}
            material={materials.lambert1}
            skeleton={nodes.HandToMaya_BASE_0_lambert1_0.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

function DancingDot() {
  const { drums, snare } = useStore(state => state.audio);
  const dot = useRef();
  useFrame(_ =>
    dot.current.rotation.set(
      Math.sin(_.clock.elapsedTime * 2) / 10 + (drums.avg * drums.gain) / 100,
      _.clock.elapsedTime + (snare.avg * snare.gain) / 100,
      0
    )
  );
  return (
    <group ref={dot}>
      <mesh position={[-120, 2, 0]}>
        <sphereGeometry args={[4, 8, 8]} />
        <meshBasicMaterial toneMapped={false} color="#FF083C" />
      </mesh>
    </group>
  );
}

const Pill = props => {
  const { drums, snare } = useStore(state => state.audio);
  const group = useRef();
  const { nodes, materials } = useGLTFLoader("music/pill/scene.gltf", true);

  useFrame(_ => {
    group.current.rotation.set(
      Math.sin(_.clock.elapsedTime * 2) / 10 + (drums.avg * drums.gain) / 100,
      _.clock.elapsedTime + (snare.avg * snare.gain) / 100,
      0
    );
  });

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      scale={[3, 3, 3]}
      position={[0, 50, 0]}
    >
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.pCylinder1_red_0.geometry}
            material={materials.material}
          />
          <mesh
            geometry={nodes.pCylinder1_white_0.geometry}
            material={materials.white}
          />
        </group>
      </group>
    </group>
  );
};

// const Heart = props => {
//   const { drums, snare } = useStore(state => state.audio);
//   const group = useRef();
//   const { nodes, materials } = useGLTFLoader("music/crystal/scene.gltf", true);
//
//   useFrame(_ => {
//     group.current.rotation.set(
//       Math.sin(_.clock.elapsedTime * 2) / 10 + (drums.avg * drums.gain) / 100,
//       _.clock.elapsedTime + (snare.avg * snare.gain) / 100,
//       0
//     );
//   });
//
//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group rotation={[-Math.PI / 2, 0, 0]}>
//         <group rotation={[Math.PI / 2, 0, 0]}>
//           <group rotation={[-Math.PI / 2, 0, 0]}>
//             <mesh
//               geometry={nodes["Hart_02_-_Default_0"].geometry}
//               material={materials["02_-_Default"]}
//             />
//           </group>
//         </group>
//       </group>
//     </group>
//   );
// };

const Model = () => {
  return (
    <>
      <Canvas
        camera={{ position: [0, -60, 180] }}
        style={{ background: "#ADA9FD" }}
      >
        <ambientLight intensity={0.01} />
        <OrbitControls enableDamping enablePan={false} enableZoom={false} />
        <fog attach="fog" args={["#d0d0d0", 1, 1900]} />
        <directionalLight position={[0, 100, -100]} intensity={1.5} />
        <directionalLight position={[-10, 10, 5]} intensity={1} />
        <directionalLight position={[-10, 20, 0]} intensity={1.5} />
        <directionalLight position={[0, -10, 0]} intensity={0.25} />
        <Suspense fallback={null}>
          <Pill />
          {/* <Heart scale={[0.3, 0.3, 0.3]} position={[0, 30, 0]} /> */}
          <Face />
          <Hand position={[0, -50, 0]} />
          <DancingDot />
          <Intro />
        </Suspense>
      </Canvas>
      <Loader
        containerStyles={{ backgroundColor: 0x171717 }}
        barStyles={{ backgroundColor: "silver" }}
        dataInterpolation={p => `Loading`}
      />
    </>
  );
};

export default Model;
