import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";
import { OrbitControls, useGLTFLoader, Loader, Stars } from "drei";
import { Helmet } from "react-helmet";
import { initGA, PageView } from "../../components/tracking";
import { Lensflare, LensflareElement } from "../Ax/Lensflare.js";
import lensflare0 from "../Ax/assets/lensflare0.png";
import lensflare3 from "../Ax/assets/lensflare3.png";

function Laocoon(props) {
  const { camera, clock } = useThree();
  const group = useRef();

  const urls = ["/1.png", "/2.png", "/3.png", "/4.png", "/5.png", "/6.png"];
  const reflectionCube = new THREE.CubeTextureLoader().load(urls);
  const refractionCube = new THREE.CubeTextureLoader().load(urls);
  refractionCube.mapping = THREE.CubeRefractionMapping;
  // const cubeMaterial3 = new THREE.MeshLambertMaterial({
  //   color: 0x0080ff,
  //   envMap: reflectionCube,
  //   combine: THREE.MixOperation,
  //   reflectivity: 1
  // });
  // const cubeMaterial2 = new THREE.MeshLambertMaterial({
  //   color: 0x0080ff,
  //   envMap: refractionCube,
  //   refractionRatio: 0.95
  // });
  const cubeMaterial1 = new THREE.MeshLambertMaterial({
    color: 0x0080ff,
    envMap: reflectionCube
  });
  const { nodes } = useGLTFLoader("/models/laocoon/scene.gltf", true);

  useFrame(() => {
    // console.log(clock);
    // camera.rotation.y = camera.rotation.z += Math.sin(clock.elapsedTime) / 10;
    camera.position.y += Math.cos(clock.elapsedTime) * 4.1;
    camera.rotation.y += Math.cos(clock.elapsedTime) / 10;
  });

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      scale={[25, 25, 25]}
      position={[-200, -400, 400]}
    >
      <group rotation={[-Math.PI / 1.4, 0, -1.3]}>
        <group
          position={[-0.35, -14.17, 0]}
          rotation={[-Math.PI / 1.7, Math.PI / 2, 0]}
        >
          <mesh geometry={nodes.mesh_0.geometry} material={cubeMaterial1}>
            {/* <meshBasicMaterial wireframe={true} color="silver" /> */}
          </mesh>
        </group>
      </group>
    </group>
  );
}

const Lights = () => {
  const { scene, gl } = useThree();
  const textureLoader = new THREE.TextureLoader();

  const textureFlare0 = textureLoader.load(lensflare0);
  const textureFlare3 = textureLoader.load(lensflare3);

  function addLight(h, s, l, x, y, z) {
    const light = new THREE.PointLight(0xffffff, 0.001, 200000000);
    light.color.setHSL(h, s, l);
    light.position.set(x, y, z);
    scene.add(light);

    const lensflare = new Lensflare();
    lensflare.addElement(
      new LensflareElement(textureFlare0, 999, 0, light.color)
    );
    lensflare.addElement(new LensflareElement(textureFlare3, 100, 0.1));
    lensflare.addElement(new LensflareElement(textureFlare3, 1200, 0.5));
    lensflare.addElement(new LensflareElement(textureFlare3, 2900, 0.3));
    lensflare.addElement(new LensflareElement(textureFlare3, 70, 1));
    light.add(lensflare);
  }

  addLight(0.53, 0.9, 0.5, 7000, 1000, -1300);
  // addLight(0.53, 0.9, 0.5, 1000, 0, -4000);
  // addLight(0.53, 0.9, 0.5, 1000, 0, -4000);

  gl.outputEncoding = THREE.sRGBEncoding;
  gl.toneMapping = THREE.NoToneMapping;

  return null;
};

const Animation = () => {
  // const [play, setPlay] = useState(false);
  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
      <Helmet>
        <title>here</title>
      </Helmet>
      <Canvas
        style={{ background: "#090909" }}
        camera={{
          position: [-2100, 200, 400],
          far: 100000000,
          fov: 50,
          rotation: [
            -0.3563005451087305,
            0.5614655027896599,
            0.20237380924409817
          ]
        }}
      >
        <OrbitControls />
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
          <Lights />
          <Laocoon />
        </Suspense>
        <Stars
          radius={10000000} // Radius of the inner sphere (default=100)
          depth={1000} // Depth of area where stars should fit (default=50)
          count={15000} // Amount of stars (default=5000)
          factor={40} // Size factor (default=4)
          saturation={1} // Saturation 0-1 (default=0)
          fade // Faded dots (default=false)
        />
      </Canvas>
      <Loader
        containerStyles={{ backgroundColor: 0x171717 }}
        barStyles={{ backgroundColor: "silver" }}
        dataInterpolation={p => `Loading`}
      />
      {/* <button className="sound-btn" onClick={() => handleClick()}>{play ? 'pause' : 'play'}</button> */}
    </>
  );
};

export default Animation;
