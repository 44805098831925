export const vshader = `
attribute float vertexDisplacement;
uniform float delta;
varying float vOpacity;
varying vec3 vUv;
void main() {
  vUv = position;
  vOpacity = vertexDisplacement;

  vec3 p = position;

  p.x += sin(vertexDisplacement) * 15.0;
  p.y += cos(vertexDisplacement) * 5.0;

  vec4 modelViewPosition = modelViewMatrix * vec4(p, 1.0);
  gl_Position = projectionMatrix * modelViewPosition;
}
`;

export const fshader = `
uniform float delta;
varying float vOpacity;
varying vec3 vUv;
void main(){
  float r = 1.0 * cos(vUv.x * delta);
  float g = 0.6 + sin(delta) * 0.4;
  float b = 0.4 + cos(delta * vUv.y) * 0.6;
  gl_FragColor = vec4(r, g, b, vOpacity);
}
`;
