import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';

const Jon = () => {
  const myRef = useRef();

  useEffect(() => {
    initGA();
    PageView();
  });

  let scene, camera, renderer;

  let cameraControls;

  let sphereGroup, smallSphere;

  let groundMirror, verticalMirror;

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  scene = new THREE.Scene();

  camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    1,
    5000
  );
  camera.position.set(0, 75, 160);
  camera.lookAt(0, 0, 0);

  cameraControls = new OrbitControls(camera, renderer.domElement);
  cameraControls.target.set(0, 40, 0);
  cameraControls.maxDistance = 400;
  cameraControls.minDistance = 10;
  cameraControls.update();

  const planeGeo = new THREE.PlaneGeometry(100, 100);

  let geometry, material;

  geometry = new THREE.CircleGeometry(40, 64);
  groundMirror = new Reflector(geometry, {
    clipBias: 0.003,
    textureWidth: window.innerWidth * devicePixelRatio,
    textureHeight: window.innerHeight * window.devicePixelRatio,
    color: 0x777777,
  });
  groundMirror.position.y = 0.5;
  groundMirror.rotateX(-Math.PI / 2);
  scene.add(groundMirror);

  geometry = new THREE.PlaneGeometry(100, 100);
  verticalMirror = new Reflector(geometry, {
    clipBias: 0.003,
    textureWidth: window.innerWidth * window.devicePixelRatio,
    textureHeight: window.innerHeight * window.devicePixelRatio,
    color: 0x889999,
  });
  verticalMirror.position.y = 50;
  verticalMirror.position.z = -50;
  scene.add(verticalMirror);

  // var verticalMirror2 = new Reflector(geometry, {
  //   clipBias: 0.003,
  //   textureWidth: window.innerWidth * window.devicePixelRatio,
  //   textureHeight: window.innerHeight * window.devicePixelRatio,
  //   color: 0x889999,
  // });
  // verticalMirror2.position.y = 50;
  // verticalMirror2.position.z = 50;
  // verticalMirror2.rotateY(Math.PI);
  // scene.add(verticalMirror2);

  sphereGroup = new THREE.Object3D();
  // scene.add(sphereGroup);

  geometry = new THREE.CylinderGeometry(
    0.1,
    15 * Math.cos((Math.PI / 180) * 30),
    0.1,
    24,
    1
  );
  material = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    emissive: 0x444444,
  });
  const sphereCap = new THREE.Mesh(geometry, material);
  sphereCap.position.y = -15 * Math.sin((Math.PI / 180) * 30) - 0.05;
  sphereCap.rotateX(-Math.PI);

  geometry = new THREE.SphereGeometry(
    15,
    24,
    24,
    Math.PI / 2,
    Math.PI * 2,
    0,
    (Math.PI / 180) * 120
  );
  const halfSphere = new THREE.Mesh(geometry, material);
  halfSphere.add(sphereCap);
  halfSphere.rotateX((-Math.PI / 180) * 135);
  halfSphere.rotateZ((-Math.PI / 180) * 20);
  halfSphere.position.y = 7.5 + 15 * Math.sin((Math.PI / 180) * 30);

  sphereGroup.add(halfSphere);

  geometry = new THREE.IcosahedronGeometry(5, 0);
  material = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    emissive: 0x333333,
    flatShading: true,
  });
  smallSphere = new THREE.Mesh(geometry, material);
  scene.add(smallSphere);

  // lights
  const mainLight = new THREE.PointLight(0xcccccc, 1.5, 250);
  mainLight.position.y = 60;
  scene.add(mainLight);

  const greenLight = new THREE.PointLight(0xdbd851, 0.25, 1000);
  greenLight.position.set(550, 50, 0);
  scene.add(greenLight);

  const redLight = new THREE.PointLight(0xde7a7e, 0.25, 1000);
  redLight.position.set(-550, 50, 0);
  scene.add(redLight);

  const blueLight = new THREE.PointLight(0x68b0db, 0.25, 1000);
  blueLight.position.set(0, 50, 550);
  scene.add(blueLight);

  // walls
  const planeTop = new THREE.Mesh(
    planeGeo,
    new THREE.MeshPhongMaterial({ color: 0xffffff })
  );
  planeTop.position.y = 100;
  planeTop.rotateX(Math.PI / 2);
  scene.add(planeTop);

  const planeBottom = new THREE.Mesh(
    planeGeo,
    new THREE.MeshPhongMaterial({ color: 0xffffff })
  );
  planeBottom.rotateX(-Math.PI / 2);
  scene.add(planeBottom);

  const planeFront = new THREE.Mesh(
    planeGeo,
    new THREE.MeshPhongMaterial({ color: 0x68b0db })
  );
  planeFront.position.z = 50;
  planeFront.position.y = 50;
  planeFront.rotateY(Math.PI);
  scene.add(planeFront);

  const planeRight = new THREE.Mesh(
    planeGeo,
    new THREE.MeshPhongMaterial({ color: 0xdbd851 })
  );
  planeRight.position.x = 50;
  planeRight.position.y = 50;
  planeRight.rotateY(-Math.PI / 2);
  scene.add(planeRight);

  const planeLeft = new THREE.Mesh(
    planeGeo,
    new THREE.MeshPhongMaterial({ color: 0xde7a7e })
  );
  planeLeft.position.x = -50;
  planeLeft.position.y = 50;
  planeLeft.rotateY(Math.PI / 2);
  scene.add(planeLeft);

  const loader = new GLTFLoader();
  let object;
  loader.load('/models/crow/scene.gltf', (gltf) => {
    object = gltf;
    gltf.scene.traverse(function (child) {
      if (child.isMesh) {
        const m = child;
        m.receiveShadow = true;
        m.castShadow = true;
      }
      if (child.isLight) {
        const l = child;
        l.castShadow = true;
        l.shadow.bias = -0.003;
        l.shadow.mapSize.width = 2048;
        l.shadow.mapSize.height = 2048;
      }
    });

    gltf.scene.position.set(1, 9.5 + 15 * Math.sin((Math.PI / 180) * 30), -5);
    gltf.scene.scale.set(0.1, 0.1, 0.1);
    // gltf.scene.rotation.set((5 * Math.PI * 0.96) / 3, Math.PI, 0);

    scene.add(gltf.scene);
  });

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  function animate() {
    requestAnimationFrame(animate);

    const timer = Date.now() * 0.01;

    if (object) {
      object.scene.rotation.y -= 0.029;
      object.scene.position.y = Math.abs(Math.cos(timer * 0.1)) * 15 + 10;
    }

    smallSphere.position.set(
      Math.cos(timer * 0.1) * 30,
      Math.abs(Math.cos(timer * 0.2)) * 20 + 5,
      Math.sin(timer * 0.1) * 30
    );
    smallSphere.rotation.y = Math.PI / 2 - timer * 0.1;
    smallSphere.rotation.z = timer * 0.8;

    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>You Know Nothing</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
};

export default Jon;
