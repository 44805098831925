import React, { useRef } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import {Helmet} from 'react-helmet';
import { Html } from 'drei';
import './NotFound.css';

const Dodecahedron = ({position, scale, textCopy}) => {
  return (
    <mesh position={position} scale={scale}>
      <torusKnotGeometry attach="geometry" />
      <meshStandardMaterial attach="material" roughness={0.75} emissive="#404057"/>
      <Html scaleFactor={40}>
        <div className="content">
          <h5 className="primary-color">{textCopy}</h5>
        </div>
      </Html>
    </mesh>
  );
}

function Content() {
  const ref = useRef()
  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z += 0.01))
  return (
    <group ref={ref}>
      <Dodecahedron time={500} position={[-2, 0, 0]} scale={[0.5,0.5,0.5]} textCopy="hurry up!!"/>
      <Dodecahedron time={1000} position={[0, -2, -3]} scale={[0.75,0.75,0.75]} textCopy="you got lost!!"/>
      <Dodecahedron time={1500} position={[2, 0, 0]} scale={[0.25,0.25,0.25]} textCopy=":((("/>
    </group>
  )
}


const NotFound = () => {
  return (
    <>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
    <Canvas colorManagement id="not-found-c">
      <pointLight color="indianred" />
      <pointLight position={[10, 10, -10]} color="orange" />
      <pointLight position={[-10, -10, 10]} color="lightblue" />
      <Content />
    </Canvas>
    </>
  );
}

export default NotFound;
