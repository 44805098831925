import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default function Q() {
  useEffect(() => {
    initGA();
    PageView();
  });

  const myRef = useRef();

  var camera, scene, renderer;
  var particleLight;

  camera = new THREE.PerspectiveCamera(
    40,
    window.innerWidth / window.innerHeight,
    1,
    2500
  );
  camera.position.set(0.0, 400, 400 * 3.5);

  const reflectionCube = new THREE.CubeTextureLoader()
    .setPath('cube/')
    .load(['px.jpeg', 'nx.jpeg', 'py.jpeg', 'ny.jpeg', 'pz.jpeg', 'nz.jpeg']);
  reflectionCube.encoding = THREE.sRGBEncoding;

  scene = new THREE.Scene();
  scene.background = reflectionCube;

  let imgTexture = new THREE.TextureLoader().load('moon_1024.jpg');
  imgTexture.wrapS = imgTexture.wrapT = THREE.RepeatWrapping;
  imgTexture.encoding = THREE.sRGBEncoding;
  imgTexture.anisotropy = 16;
  imgTexture = null;

  // const cubeWidth = 500;
  const numberOfSpheresPerSide = 6;
  // const sphereRadius = (cubeWidth / numberOfSpheresPerSide) * 0.8 * 0.5;
  const stepSize = 1.0 / numberOfSpheresPerSide;

  const loader = new THREE.BufferGeometryLoader();
  loader.load('models/json/walt.json', function (geometryOne) {
    geometryOne.computeVertexNormals();
    geometryOne.scale(1.2, 1.2, 1.2);

    const geometry = geometryOne;

    for (let alpha = 0; alpha <= 1.0; alpha += stepSize) {
      for (let beta = 0; beta <= 1.0; beta += stepSize) {
        for (let gamma = 0; gamma <= 1.0; gamma += stepSize) {
          const diffuseColor = new THREE.Color().setHSL(
            alpha,
            0.5,
            gamma * 0.5 + 0.1
          );

          const material = new THREE.MeshLambertMaterial({
            map: imgTexture,
            color: diffuseColor,
            reflectivity: beta,
            envMap: alpha < 0.5 ? reflectionCube : null,
          });

          const mesh = new THREE.Mesh(geometry, material);

          mesh.position.x = alpha * 400 - 200;
          mesh.position.y = beta * 400 - 200;
          mesh.position.z = gamma * 400 - 200;

          scene.add(mesh);
        }
      }
    }
  });

  // const geometry = new THREE.SphereGeometry(sphereRadius, 32, 16);

  particleLight = new THREE.Mesh(
    new THREE.SphereGeometry(4, 8, 8),
    new THREE.MeshBasicMaterial({ color: 0xffffff })
  );
  scene.add(particleLight);

  scene.add(new THREE.AmbientLight(0x222222));

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(1, 1, 1).normalize();
  scene.add(directionalLight);

  const pointLight = new THREE.PointLight(0xffffff, 2, 800);
  particleLight.add(pointLight);

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.outputEncoding = THREE.sRGBEncoding;

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.minDistance = 200;
  controls.maxDistance = 2000;

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  function animate() {
    requestAnimationFrame(animate);

    render();
  }

  function render() {
    const timer = Date.now() * 0.00025;

    camera.lookAt(scene.position);
    particleLight.position.x = Math.sin(timer * 7) * 300;
    particleLight.position.y = Math.cos(timer * 5) * 400;
    particleLight.position.z = Math.cos(timer * 3) * 300;

    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>Help me see</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
}
