import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';
import { vshader, fshader } from '../Q/shaders';

export default function Wonder() {
  useEffect(() => {
    initGA();
    PageView();
  });

  const myRef = useRef();

  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    1,
    4000
  );
  camera.position.set(0, 0, 3000);

  const scene = new THREE.Scene();

  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  // renderer.toneMapping = THREE.ACESFilmicToneMapping;
  // renderer.outputEncoding = THREE.sRGBEncoding;

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  // var geometry = new THREE.BoxBufferGeometry(3, 3, 3);

  var uniforms = {
    delta: { values: 0 },
  };
  var material = new THREE.ShaderMaterial({
    vertexShader: vshader,
    fragmentShader: fshader,
    uniforms,
  });

  // var geometry3 = new THREE.PlaneGeometry(10000, 10000, 100, 100);
  // var mesh3 = new THREE.Mesh(geometry3, material);
  // mesh3.rotation.x = (-90 * Math.PI) / 180;
  // mesh3.position.y = -100;
  // scene.add(mesh3);

  const environment = new RoomEnvironment();
  const pmremGenerator = new THREE.PMREMGenerator(renderer);

  // scene.background = new THREE.Color(0x666666);
  scene.environment = pmremGenerator.fromScene(environment).texture;

  var stepSize = 1.0 / 5.0;

  const loader = new THREE.BufferGeometryLoader();
  loader.load('models/json/walt.json', function (geometryOne) {
    geometryOne.computeVertexNormals();
    geometryOne.scale(1.2, 1.2, 1.2);

    const geometry = geometryOne;

    for (let alpha = 0; alpha <= 4.0; alpha += stepSize) {
      for (let beta = 0; beta <= 4.0; beta += stepSize) {
        for (let gamma = 0; gamma <= 4.0; gamma += stepSize) {
          const diffuseColor = new THREE.Color().setHSL(
            alpha,
            0.5,
            gamma * 0.5 + 0.1
          );

          const material2 = material;

          const mesh = new THREE.Mesh(geometry, material2);

          mesh.position.x = alpha * 400 - 800;
          mesh.position.y = beta * 400 - 800;
          mesh.position.z = gamma * 400 - 400;

          scene.add(mesh);
        }
      }
    }
  });

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.minDistance = 200;
  controls.maxDistance = 3000;

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  function animate() {
    requestAnimationFrame(animate);

    render();
  }

  var delta = 0;
  function render() {
    // const timer = Date.now() * 0.00025;

    delta += 0.1;

    //uniform
    uniforms.delta.value = 0.1 + Math.sin(delta) * 1.2;

    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>restless all time</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
}
