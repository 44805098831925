import React from 'react';
import { Link } from 'react-router-dom';

const NavLinks = () => {
  return (
    <div className="main-nav">
      <ul>
        <Link to="/city">
          <li>city</li>
        </Link>
        <Link to="/ahead">
          <li>ahead</li>
        </Link>
        <Link to="/evol">
          <li>evol</li>
        </Link>
        <Link to="/kiara">
          <li>kiara</li>
        </Link>
        <Link to="/swinging-door">
          <li>swinging door</li>
        </Link>
        <Link to="/with-style">
          <li>with style</li>
        </Link>
        <Link to="/honey">
          <li>honey</li>
        </Link>
        <Link to="/versicolor">
          <li>versicolor</li>
        </Link>
        <Link to="/not-me">
          <li>not-me</li>
        </Link>
        <Link to="/here">
          <li>here</li>
        </Link>
        <Link to="/truth-ray">
          <li>truth ray</li>
        </Link>
        <Link to="/poison">
          <li>poison</li>
        </Link>
        <Link to="/you-again">
          <li>you again</li>
        </Link>
        <Link to="/centre">
          <li>in the centre</li>
        </Link>
        <Link to="/where">
          <li>where?</li>
        </Link>
        <Link to="/find-me">
          <li>find me</li>
        </Link>
        <Link to="/hello">
          <li>hello?</li>
        </Link>
        <Link to="/never-left">
          <li>never left</li>
        </Link>
        <Link to="/kana">
          <li>kana</li>
        </Link>
        <Link to="/nine">
          <li>nine</li>
        </Link>
        <Link to="/a-cat">
          <li>a cat</li>
        </Link>
        <Link to="/one-day">
          <li>one day</li>
        </Link>
        <Link to="/q">
          <li>Q</li>
        </Link>
        <Link to="/meta">
          <li>meta</li>
        </Link>
        <Link to="/the-hand">
          <li>the hand</li>
        </Link>
        <Link to="/yes">
          <li>yes</li>
        </Link>
        <Link to="/mahsa">
          <li>#mahsa</li>
        </Link>
      </ul>
    </div>
  );
};

export default NavLinks;
