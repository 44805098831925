import React from "react";

import "./Overlay.css";

export default function Overlay({ ready, clicked, setClicked, children }) {
  return (
    <>
      <div
        className={`fullscreen bg ${ready ? "ready" : "notready"} ${clicked &&
          "clicked"}`}
      >
        {children}
      </div>
    </>
  );
}
