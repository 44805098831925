import React, { useState } from 'react';

import NavLinks from './NavLinks';
import MainLink from '../navigation/MainLink';
import SideDrawer from '../navigation/SideDrawer';
import Backdrop from '../navigation/Backdrop';
import factory from './factory.gif';

const Navigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks />
          <div className="gif-ctn">
            <div className="credit-ctn">
              <img className="factory-gif" src={factory} alt="factory" />
              <span className="footer-credit">
                © {new Date().getFullYear()} unsustainable growth.
              </span>
            </div>
            <div className="footer-other-links">
              <MainLink />
              <p className="ig-credit">
                <a href="https://www.instagram.com/score59/">@score59</a>
              </p>
            </div>
          </div>
        </nav>
      </SideDrawer>
      <div className="main-header">
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <nav className="main-navigation__header-nav">
          <NavLinks />
        </nav>
      </div>
    </>
  );
};

export default Navigation;
