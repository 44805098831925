import React, { useEffect, Suspense } from 'react';
import * as THREE from 'three';
import { AsciiEffect } from './AsciiEffect';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import { TrackballControls, Loader } from 'drei';
import { OBJLoader } from '../Face/OBJLoader';
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';


const Sphere = () => {
  const start = Date.now();
  // const sphereRef = useRef();


  const { scene, camera } = useThree();
  const renderer = new THREE.WebGLRenderer();
	renderer.setSize( window.innerWidth, window.innerHeight );

  const effect = new AsciiEffect( renderer, ' .:-+*=%@#?', { invert: true } );
  effect.setSize( window.innerWidth, window.innerHeight );
	effect.domElement.style.color = 'powderblue';
	effect.domElement.style.backgroundColor = 'black';
  effect.domElement.style.position = 'absolute';
  effect.domElement.style.top = '0';

	// Special case: append effect.domElement, instead of renderer.domElement.
	// AsciiEffect creates a custom domElement (a div container) where the ASCII elements are placed.

	useEffect(() => {
    document.body.appendChild( effect.domElement );
    return () => {
      document.body.removeChild( effect.domElement );
    }
  }, [])

  const cubeMaterial1 = new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true } );

  const objLoader = new OBJLoader();

	objLoader.setPath( 'models/' );
	objLoader.load( 'woman.obj', function ( object ) {

		const head = object.children[ 0 ];

		head.scale.multiplyScalar( 2.7 );
		head.position.y = -80;
		head.material = cubeMaterial1;
    head.rotation.x = Math.PI / 2.7

		scene.add( head);

	} );


  useFrame(() => {
    const timer = Date.now() - start;
    // camera.position.y = Math.abs( Math.sin( timer * 0.0002 ) ) * 500;
    camera.position.y = Math.sin( timer * 0.0001 ) * 300;
    // camera.rotation.x = timer * 0.0003;
    camera.position.x = Math.cos( timer * 0.0001 ) * 600;
    // sphereRef.current.rotation.z = timer * 0.0002;
    effect.render( scene, camera );
  });

  return (
    null
    // <mesh ref={sphereRef}>
    //   <sphereGeometry args={[200, 20, 10 ]} attach="geometry" />
    //   <meshPhongMaterial flatShading={true} />
    // </mesh>
  );
}

const Ascii = () => {

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
    <Helmet>
        <title>make it work 🏡</title>
    </Helmet>
    <Canvas camera={{ position: [0, 0, 800], fov: 70, near: 1, far: 1000 }} style={{ background: '#000000' }}>
      <pointLight position={[500,500,500]} color="white" />
      <pointLight position={[-500, -500, -500]} intensity={0.25} />
      <Suspense fallback={null}>
        <Sphere />
        {/* <Plane args={[400,400]} position={[0,-200, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <meshBasicMaterial attach="material" color={0xe0e0e0}/>
        </Plane> */}
      </Suspense>
      <TrackballControls noZoom={true}/>
    </Canvas>
    <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    </>
  );
}

export default Ascii;
