import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { Canvas, useThree, useFrame } from "react-three-fiber";
import { OrbitControls } from "drei";
import { vshader, fshader } from "./shaders";

const Sphere = () => {
  const sphereRef = useRef();

  const {
    size,
    clock // THREE.Clock (useful for useFrame deltas)
  } = useThree();

  const { width, height } = size;

  const uniforms = {
    u_color: { value: new THREE.Color(0x00ff00) },
    u_time: { value: 0.0 },
    u_mouse: { value: { x: 0, y: 0 } },
    u_resolution: { value: { x: 0.0, y: 0.0 } }
  };

  const move = e => {
    uniforms.u_mouse.value.x = e.touches ? e.touches[0].clientX : e.clientX;
    uniforms.u_mouse.value.y = e.touches ? e.touches[0].clientY : e.clientY;
  };

  useEffect(() => {
    uniforms.u_resolution.value.x = width;
    uniforms.u_resolution.value.y = height;
    if ("ontouchstart" in window) {
      document.addEventListener("touchmove", move);
    } else {
      document.addEventListener("mousemove", move);
    }

    return () => {
      if ("ontouchstart" in window) {
        document.removeEventListener("touchmove", move);
      } else {
        document.removeEventListener("mousemove", move);
      }
    };
  });

  useFrame(() => {
    uniforms.u_time.value = clock.elapsedTime;
    sphereRef.current.rotation.x += 0.001;
  });

  return (
    <mesh ref={sphereRef}>
      <cylinderBufferGeometry attach="geometry" args={[5, 5, 20, 32]} />
      <shaderMaterial
        side={THREE.DoubleSide}
        vertexShader={vshader}
        fragmentShader={fshader}
        uniforms={uniforms}
      />
    </mesh>
  );
};

const NewShaders = () => {
  return (
    <Canvas camera={{ position: [0, 0, 30] }}>
      <Sphere />
      <OrbitControls />
    </Canvas>
  );
};

export default NewShaders;
