import React, { Suspense, useEffect } from "react"
import { Canvas } from "react-three-fiber"
import Sprite from "./Sprite"
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';

const SpriteAnimation = () => {
  useEffect(() => {
    initGA();
    PageView();
  })
  return (
    <>
    <Helmet>
        <title>i'm a clown 🤡</title>
    </Helmet>
    <Canvas style={{ backgroundColor: '#c5c8d9' }} camera={{ position: [0, 1, 5] }}>
      <Suspense fallback={null}>
        <Sprite IconPosition={[0, 0, 0]} IconSize={[4, 4, 0.1]} textureSrc="eye.png" />
      </Suspense>
      <ambientLight intensity={1} />
    </Canvas>
    </>
  )
}

export default SpriteAnimation;
