import React from "react";
import useStore from "./store";

function Overlay() {
  const loaded = useStore(state => state.loaded);
  const clicked = useStore(state => state.clicked);
  const api = useStore(state => state.api);
  return (
    <>
      <div
        className={`fullscreen bg ${loaded ? "loaded" : "notready"} ${clicked &&
          "clicked"}`}
      >
        <div className="ends" onClick={() => loaded && api.start()}>
          {!loaded ? (
            "loading"
          ) : (
            <>
              <span style={{ color: "#fff" }}>when this ends</span>
              <br />
              <span style={{ color: "#fff" }}>something begins</span>
              <br />
              <b>
                <span style={{ color: "yellow" }}>wait ...</span>
              </b>
            </>
          )}
        </div>
        <span className="audio-note">
          <span style={{ color: "greenyellow" }}>!important</span>
          <br />
          ***** An Audio track will play by clicking above *****
        </span>
      </div>
    </>
  );
}

export default Overlay;
