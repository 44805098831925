import React, { useRef, Suspense, useState, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from 'react-three-fiber';
import { useGLTFLoader, Loader, Stars } from 'drei';
import { Helmet } from 'react-helmet';
// import * as dat from 'dat.gui';

import { initGA, PageView } from '../../components/tracking';
import Overlay from './Overlay';

import './index.css';

const Robot = (props) => {
  const group = useRef();

  // const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  useFrame(() => {
    if (props.progress > 0 && props.progress < 1) {
      group.current.rotation.y = Math.PI * props.progress * 2;
    }
  });

  const { nodes } = useGLTFLoader('/models/robot/scene.gltf', true);
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[0, 0.02, 2.36]} scale={[0.88, 0.67, 0.83]}>
          <group position={[0.67, -0.04, -1.62]} scale={[0.19, 0.39, 0.72]}>
            <group
              position={[0.77, -0.9, -1.25]}
              rotation={[0, -0.11, 0.04]}
              scale={[2.39, 2.66, 0.38]}
            >
              <mesh
                geometry={nodes.Foot_Left_1.geometry}
                material={nodes.Foot_Left_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Leg_Left_1.geometry}
              material={nodes.Leg_Left_1.material}
            />
          </group>
          <group position={[-0.68, 0, -1.51]} scale={[0.19, 0.39, 0.72]}>
            <group
              position={[-0.57, -1.02, -1.41]}
              rotation={[0, -0.11, 0.04]}
              scale={[2.39, 2.66, 0.38]}
            >
              <mesh
                geometry={nodes.Foot_Right_1.geometry}
                material={nodes.Foot_Right_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Leg_Right_1.geometry}
              material={nodes.Leg_Right_1.material}
            />
          </group>
          <group position={[0, -0.06, 1.18]} scale={[0.83, 1.1, 1.08]}>
            <group position={[0, 0.02, 1.55]} scale={[2.15, 1.45, 1.35]}>
              <group position={[-1.14, -0.19, 2.1]} scale={[0.05, 0.08, 0.07]}>
                <mesh
                  geometry={nodes.Antenna_Right_Top_1.geometry}
                  material={nodes.Antenna_Right_Top_1.material}
                />
              </group>
              <group position={[-1.03, -0.19, 1.19]} scale={[0.63, 0.94, 0.83]}>
                <mesh
                  geometry={nodes.Antenna_Right_1.geometry}
                  material={nodes.Antenna_Right_1.material}
                />
              </group>
              <group
                position={[-0.46, -1.02, 0.13]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[1.38, 1.79, 0.94]}
              >
                <mesh
                  geometry={nodes.Eye_Right_1.geometry}
                  material={nodes.Eye_Right_1.material}
                />
              </group>
              <group
                position={[0.47, -1.02, 0.13]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[1.02, 1.32, 0.94]}
              >
                <mesh
                  geometry={nodes.Eye_Left_1.geometry}
                  material={nodes.Eye_Left_1.material}
                />
              </group>
              <group position={[1.08, -0.2, 1.96]} scale={[0.05, 0.08, 0.07]}>
                <mesh
                  geometry={nodes.Antenna_Left_Top_1.geometry}
                  material={nodes.Antenna_Left_Top_1.material}
                />
              </group>
              <group
                position={[0, -0.21, 0.11]}
                rotation={[0, Math.PI / 2, 0]}
                scale={[1.32, 1.51, 1.01]}
              >
                <mesh
                  geometry={nodes.Ears_1.geometry}
                  material={nodes.Ears_1.material}
                />
              </group>
              <mesh
                geometry={nodes.Head_1.geometry}
                material={nodes.Head_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Neck_1.geometry}
              material={nodes.Neck_1.material}
            />
          </group>
          <group
            position={[0, -0.89, -0.29]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.28, 0.3, 0.37]}
          >
            <mesh
              geometry={nodes.Pipes_1.geometry}
              material={nodes.Pipes_1.material}
            />
          </group>
          <group
            position={[0, 0.07, 0.18]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            scale={[2.93, 2.34, 1.51]}
          >
            <group
              position={[0, -0.05, -1.22]}
              rotation={[0, 0, -Math.PI / 2]}
              scale={[0.08, 0.08, 0.44]}
            >
              <group
                position={[1.9, 0, -1.3]}
                rotation={[1.17, 0.09, 1.69]}
                scale={[0.35, 1.37, 0.6]}
              >
                <mesh
                  geometry={nodes.Hand_Right_1.geometry}
                  material={nodes.Hand_Right_1.material}
                />
              </group>
              <mesh
                geometry={nodes.Arm_Right_1.geometry}
                material={nodes.Arm_Right_1.material}
              />
            </group>
            <group
              position={[0, -0.05, 1.22]}
              rotation={[0, 0, -Math.PI / 2]}
              scale={[0.08, 0.08, 0.44]}
            >
              <group
                position={[1.99, 0, 1.31]}
                rotation={[1.17, 0.09, 1.69]}
                scale={[0.35, 1.37, 0.6]}
              >
                <mesh
                  geometry={nodes.Hand_Left_1.geometry}
                  material={nodes.Hand_Left_1.material}
                />
              </group>
              <mesh
                geometry={nodes.Arm_Left_1.geometry}
                material={nodes.Arm_Left_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Shoulders_1.geometry}
              material={nodes.Shoulders_1.material}
            />
          </group>
          <mesh
            geometry={nodes.Body_1.geometry}
            material={nodes.Body_1.material}
          />
        </group>
        <group
          position={[-6.08, -14.65, 10.98]}
          rotation={[0.96, -0.33, -0.95]}
          scale={[1, 1, 1]}
        />
        <group
          position={[15.73, 3.3, 10.98]}
          rotation={[-0.83, 1.19, -1.81]}
          scale={[1, 1, 1]}
        />
      </group>
    </group>
  );
};

const vec = new THREE.Vector3();
function Lights(props) {
  // const groupL = useRef();
  // const groupR = useRef();
  // const front = useRef();
  const slGroup = useRef();
  const [light, set] = useState();
  // const viewport = useThree((state) => state.viewport);

  useFrame(({ mouse }) => {
    // front.current.position.y = lerp(front.current.position.y, mouse.y * 14, 0);
    slGroup.current.position.lerp(vec.set(mouse.x, mouse.y, 0), 0.3);
    // light.target.position.lerp(
    //   vec.set(
    //     (mouse.x * viewport.width) / 2,
    //     (mouse.y * viewport.height) / 2,
    //     0
    //   ),
    //   0.1
    // );
  });

  return (
    <>
      <group ref={slGroup}>
        <spotLight
          castShadow
          ref={set}
          penumbra={1}
          distance={9}
          angle={0.3}
          attenuation={20}
          anglePower={35}
          intensity={1.8}
          {...props}
        />
        {light && <primitive object={light.target} />}
      </group>
    </>
  );
}

function Tv({ clicked, url, opacity, ...props }) {
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src: url,
      crossOrigin: 'Anonymous',
      loop: true,
      muted: true,
    })
  );
  useEffect(() => void (clicked && video.play()), [clicked, video]);

  // useEffect(() => {
  //   const gui = new dat.GUI();
  //   const cubeFolder = gui.addFolder('TV');
  //   cubeFolder.add(group.current.position, 'x', -20, 20, 0.5);
  //   cubeFolder.add(group.current.position, 'y', -20, 20, 0.5);
  //   cubeFolder.add(group.current.position, 'z', -20, 20, 0.5);
  //   cubeFolder.add(group.current.rotation, 'x', -Math.PI, Math.PI * 2, 0.01);
  //   cubeFolder.add(group.current.rotation, 'y', -Math.PI, Math.PI * 2, 0.01);
  //   cubeFolder.add(group.current.rotation, 'z', -Math.PI, Math.PI * 2, 0.01);
  //   cubeFolder.open();
  // }, []);

  const group = useRef();

  const standardMaterial2 = new THREE.MeshStandardMaterial({
    color: '#888989',
    metalness: 0.5,
    roughness: 0.9,
  });

  // const { nodes } = useGLTFLoader('/droid/scene.gltf');

  const { nodes } = useGLTFLoader('/mother/tv/scene.gltf', true);

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.defaultMaterial.geometry}>
              <meshBasicMaterial
                toneMapped={false}
                opacity={opacity}
                transparent
              >
                <videoTexture
                  attach="map"
                  args={[video]}
                  encoding={THREE.sRGBEncoding}
                />
              </meshBasicMaterial>
            </mesh>
          </group>
          <mesh
            geometry={nodes.defaultMaterial_1.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_2.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_3.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_4.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_5.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_6.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
        </group>
      </group>
    </group>
  );
}

function Intro({ start, set }) {
  useEffect(() => setTimeout(() => set(true), 500), [set]);
  return null;
}

const Summer = () => {
  useEffect(() => {
    initGA();
    PageView();
  }, []);

  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  return (
    <>
      <Helmet>
        <title>i wonder</title>
      </Helmet>
      <Canvas
        shadows="true"
        dpr={[1, 2]}
        camera={{ position: [-2, 2, 5], fov: 50, near: 1, far: 20 }}
        className="mother-ctn"
      >
        {/* <fog attach="fog" args={['#202020', 5, 20]} /> */}
        <pointLight position={[0, 5, -10]} intensity={0.01} />
        <ambientLight intensity={0.012} />
        <Suspense fallback={null}>
          <Robot position={[0, -1, -1.95]} scale={[0.3, 0.3, 0.3]} />
          <Lights position={[3, 3, 1]} color="#0c8cbf" />
          <Lights position={[-3, 3, 2]} color="#b00c3f" />
          <Tv
            position={[2.25, 0.5, -11]}
            rotation={[0, -1.85, 0.0]}
            {...store}
            url="/droid/butterfly.mp4"
            scale={[2.2, 2.2, 2.2]}
            opacity={1}
          />
          <Intro start={ready && clicked} set={setReady} />
        </Suspense>
        <mesh receiveShadow position={[0, -1, 0]} rotation-x={-Math.PI / 2}>
          <planeGeometry args={[50, 50]} />
          <meshPhongMaterial />
        </mesh>
        <Stars
          radius={10000000} // Radius of the inner sphere (default=100)
          depth={1000} // Depth of area where stars should fit (default=50)
          count={15000} // Amount of stars (default=5000)
          factor={40} // Size factor (default=4)
          saturation={1} // Saturation 0-1 (default=0)
          fade // Faded dots (default=false)
        />
      </Canvas>
      <Loader
        containerStyles={{ backgroundColor: 0x171717 }}
        barStyles={{ backgroundColor: 'silver' }}
        dataInterpolation={(p) => `Loading`}
      />
      <Overlay {...store} />
    </>
  );
};

export default Summer;
