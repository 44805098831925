import React from 'react';

import './MainLink.css';

const MainLink = ({ style }) => {
  return (
    <a href="https://blog.score59.xyz/" id="main-link" style={style}>
      <span>&#10033; &nbsp; &nbsp; &nbsp; &#748; &#894; &#748;</span> other side
    </a>
  );
};

export default MainLink;
