export const vshader = `
void main() {
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const fshader = `
uniform vec3 u_color;
uniform vec2 u_mouse;
uniform vec2 u_resolution;
uniform float u_time;
void main(){
  vec2 v = u_mouse / u_resolution;
  vec3 color = vec3(v.x, (sin(u_time) + 1.0 / 2.0), v.y);
  gl_FragColor = vec4(color,1.0);
}
`;
