import React, { Suspense, useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useThree, useFrame } from 'react-three-fiber';
import { Water } from './Water';
import { Sky } from './Sky';
import { OrbitControls, Loader } from 'drei';
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';

const OceanWater = () => {
  const { scene, gl } = useThree();
  const sun = new THREE.Vector3();
  const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );
  const water = new Water(
		waterGeometry,
		{
			textureWidth: 512,
			textureHeight: 512,
			waterNormals: new THREE.TextureLoader().load( 'waternormals.jpg', function ( texture ) {

				texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

			} ),
			alpha: 1.0,
			sunDirection: new THREE.Vector3(),
			sunColor: 0xffffff,
			waterColor: 0x001e0f,
			distortionScale: 3.7,
      fog: scene.fog !== undefined
		}
	);
  console.log(gl);
  gl.outputEncoding = THREE.LinearEncoding;
  gl.toneMapping = THREE.NoToneMapping;
  water.rotation.x = - Math.PI / 2;
  scene.add( water );

  const sky = new Sky();
	sky.scale.setScalar( 10000 );
	scene.add( sky );

  const skyUniforms = sky.material.uniforms;

	skyUniforms[ 'turbidity' ].value = 10;
	skyUniforms[ 'rayleigh' ].value = 2;
	skyUniforms[ 'mieCoefficient' ].value = 0.005;
	skyUniforms[ 'mieDirectionalG' ].value = 0.8;

	const parameters = {
		inclination: 0.49,
		azimuth: 0.205
	};

	const pmremGenerator = new THREE.PMREMGenerator( gl );

  const updateSun = () => {
		const theta = Math.PI * ( parameters.inclination - 0.5 );
		const phi = 2 * Math.PI * ( parameters.azimuth - 0.5 );

		sun.x = Math.cos( phi );
		sun.y = Math.sin( phi ) * Math.sin( theta );
		sun.z = Math.sin( phi ) * Math.cos( theta );

		sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
		water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

		scene.environment = pmremGenerator.fromScene( sky ).texture;

	}

	updateSun();

  useFrame(() => {
    water.material.uniforms[ 'time' ].value += 1.0 / 60.0;
  });

  return null;
}

const CubeCenter = () => {
  const cubeRef = useRef();

  useFrame(() => {
    const time = performance.now() * 0.001;
    cubeRef.current.position.y = Math.sin( time ) * 20 + 5;
    cubeRef.current.rotation.x = time * 0.5;
    cubeRef.current.rotation.z = time * 0.51;
  });

  return (
    <mesh ref={cubeRef}>
      <boxBufferGeometry args={[30,30,30]} />
      <meshStandardMaterial roughness={0} />
    </mesh>
  );
}

const Ocean = () => {
  useEffect(() => {
    initGA();
    PageView();
  });

  return (
    <>
    <Helmet>
        <title>don't let go ♡</title>
    </Helmet>
    <Canvas camera={{ position: [30, 20, 100], far: 20000, fov: 55 }}>
      <Suspense fallback={null} >
        <OceanWater />
        <CubeCenter />
      </Suspense>
      <OrbitControls maxPolarAngle={Math.PI * 0.495} minDistance={40.0} maxDistance={300.0} />
    </Canvas>
    <Loader containerStyles={{backgroundColor: 0x090909}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    </>
  );
}

export default Ocean;
