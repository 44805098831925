import React, { useState, Suspense, useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import { useGLTFLoader,OrbitControls } from 'drei';
import Overlay from './Overlay';
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';

function Model({ clicked, ...props }) {
  // const { scene } = useGLTFLoader('carla-draco.glb', true)
  const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/diamonds.mp4', crossOrigin: 'Anonymous', loop: true, muted: true }))
  useEffect(() => void (clicked && video.play()), [video, clicked])

  const [video2] = useState(() => Object.assign(document.createElement('video'), { src: '/trip.mp4', crossOrigin: 'Anonymous', loop: true, muted: true }))
  useEffect(() => void (clicked && video2.play()), [video2, clicked])

  const group = useRef()
  const { nodes } = useGLTFLoader('/models/mask/scene.gltf', true);

  useFrame((state) => {
    group.current.children.forEach((child, index) => {
      child.position.y += Math.sin(index * 1000 + state.clock.elapsedTime) / 50;
      child.rotation.x +=
        (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 2000;
      child.rotation.y +=
        (Math.cos(index * 1000 + state.clock.elapsedTime) * Math.PI) / 3000;
      child.rotation.z +=
        (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 4000;
    });
  });

  return (
    <group ref={group} dispose={null}>
      <group rotation={[0, 0, 0]} >
        <group position={[3.81, 5.48, 5.74]} rotation={[-0.96, -0.06, 0.04]} scale={[0.3, 0.3, 0.3]}>
          <mesh geometry={nodes.Mball007_0.geometry} >
            <meshBasicMaterial toneMapped={true} color="white">
              <videoTexture attach="map" args={[video2]} encoding={THREE.sRGBEncoding} />
            </meshBasicMaterial>
          </mesh>
        </group>
        <group position={[-11.91, 2.52, 1.81]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
        <group position={[-3.98, 12.19, 9.2]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
        <group position={[3.41, -3.39, 15.64]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
        <group position={[0, -54.08, 0]}>
          <mesh geometry={nodes['blMilMan_m4b_68498-sk_0'].geometry} >
            <meshBasicMaterial toneMapped={true} wireframe={false}>
              <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
            </meshBasicMaterial>
          </mesh>
        </group>
        <group position={[0, -54.08, 15]}>
          <mesh   geometry={nodes['blMilMan_m4b_68498-sk_0'].geometry} >
            <meshBasicMaterial color="white" wireframe={true} />
          </mesh>
        </group>
        <group position={[-5.33, 1.05, 5.17]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
      </group>
    </group>
  );
}

function Intro({ start, set }) {
  // const [vec] = useState(() => new THREE.Vector3())
  useEffect(() => setTimeout(() => set(true), 500), [])
  return null;
}

const Particles = () => {
  const { scene } = useThree();

  scene.fog = new THREE.FogExp2( 0x000000, 0.001 );

  const geometry = new THREE.BufferGeometry();
	const vertices = [];
  let material;

	const sprite = new THREE.TextureLoader().load( 'disc.png' );

	for ( let i = 0; i < 100000; i ++ ) {

		const x = 2000 * Math.random() - 1000;
		const y = 2000 * Math.random() - 1000;
		const z = 2000 * Math.random() - 1000;

		vertices.push( x, y, z );

	}

	geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

	material = new THREE.PointsMaterial( { size: 5, sizeAttenuation: true, map: sprite, alphaTest: 0.5, transparent: true } );
	material.color.setHSL( 1.0, 0.3, 0.7 );

	const particles = new THREE.Points( geometry, material );
	scene.add( particles );

  useFrame(() => {
    const time = Date.now() * 0.00008;
    const h = ( 360 * ( 1.0 + time ) % 360 ) / 360;
		material.color.setHSL( h, 0.5, 0.5 );
  })
  return null;
}

const Mask = () => {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
    <Helmet>
        <title>is that ...</title>
    </Helmet>
    <Canvas camera={{ position: [0,0,50], fov: 55, near:2, far: 2000 }} gl={{ antialias: false, alpha: false }}>
      <color attach="background" args={['#000']} />
      <fog attach="fog" args={['black', 50, 60]} />
      <ambientLight />
      <Suspense fallback={null}>
        <Model {...store}/>
        <Particles />
        <Intro start={ready && clicked} set={setReady} />
      </Suspense>
      <OrbitControls
        enableDamping={true}
        enablePan={false}
        enableKeys={false}
        dampingFactor={0.05}
        minPolarAngle={Math.PI / 2.1}
        maxPolarAngle={Math.PI / 2.1}
      />
    </Canvas>
    <Overlay {...store} />
    </>
  );
}

export default Mask;
