import React, { useState, Suspense, useRef, useEffect, useMemo } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {
  TextureLoader,
  WebGLRenderTarget,
  Object3D,
  LinearFilter,
} from 'three';
import { Canvas, useFrame, useThree, useLoader } from 'react-three-fiber';
import { Loader, useAspect } from 'drei';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';
import Overlay from '../Ax/Overlay';
import BackfaceMaterial from '../Ax/backface-material';
import RefractionMaterial from '../Ax/refraction-material';

function Background() {
  const texture = useLoader(TextureLoader, 'night/knight.jpg');
  console.log(texture);
  return (
    <mesh layers={1} scale={[360, 288]}>
      <planeGeometry />
      <meshBasicMaterial
        map={texture}
        map-minFilter={LinearFilter}
        depthTest={false}
      />
    </mesh>
  );
}

function DiamondsTwo({ position }) {
  const { size, viewport, gl, scene, camera, clock } = useThree();
  const model = useRef();
  // const gltf = useLoader(GLTFLoader, '/models/mask/scene.gltf');
  const gltf = useLoader(GLTFLoader, '/models/diamonds/diamond.glb');

  // Create Fbo's and materials
  const [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial] =
    useMemo(() => {
      const envFbo = new WebGLRenderTarget(size.width, size.height);
      const backfaceFbo = new WebGLRenderTarget(size.width, size.height);
      const backfaceMaterial = new BackfaceMaterial();
      const refractionMaterial = new RefractionMaterial({
        envMap: envFbo.texture,
        backfaceMap: backfaceFbo.texture,
        resolution: [size.width, size.height],
      });
      return [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial];
    }, [size]);

  // Create random position data
  const dummy = useMemo(() => new Object3D(), []);
  const diamonds = useMemo(
    () =>
      new Array(80).fill().map((_, i) => ({
        position: [
          i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width,
          40 - Math.random() * 40,
          i < 5 ? 26 : 10 - Math.random() * 20,
        ],
        factor: 0.1 + Math.random(),
        direction: Math.random() < 0.5 ? -1 : 1,
        rotation: [
          Math.sin(Math.random()) * Math.PI,
          Math.sin(Math.random()) * Math.PI,
          Math.cos(Math.random()) * Math.PI,
        ],
      })),
    [viewport.width]
  );

  // Render-loop
  useFrame(() => {
    // Update instanced diamonds
    diamonds.forEach((data, i) => {
      const t = clock.getElapsedTime();
      data.position[1] -= (data.factor / 5) * data.direction;
      if (data.direction === 1 ? data.position[1] < -50 : data.position[1] > 50)
        data.position = [
          i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width,
          100 * data.direction,
          data.position[2],
        ];
      const { position, rotation, factor } = data;
      dummy.position.set(position[0], position[1], position[2]);
      dummy.rotation.set(
        rotation[0] + t * factor,
        rotation[1] + t * factor,
        rotation[2] + t * factor
      );
      dummy.scale.set(4 + factor, 4 + factor, 4 + factor);
      dummy.updateMatrix();
      model.current.setMatrixAt(i, dummy.matrix);
    });
    model.current.instanceMatrix.needsUpdate = true;
    // Render env to fbo
    gl.autoClear = false;
    camera.layers.set(1);
    gl.setRenderTarget(envFbo);
    gl.render(scene, camera);
    // Render cube backfaces to fbo
    camera.layers.set(0);
    model.current.material = backfaceMaterial;
    gl.setRenderTarget(backfaceFbo);
    gl.clearDepth();
    gl.render(scene, camera);
    // Render env to screen
    camera.layers.set(1);
    gl.setRenderTarget(null);
    gl.render(scene, camera);
    gl.clearDepth();
    // Render cube with refraction material to screen
    camera.layers.set(0);
    model.current.material = refractionMaterial;
    gl.render(scene, camera);
  }, 1);

  return (
    <instancedMesh ref={model} args={[null, null, diamonds.length]}>
      <bufferGeometry
        dispose={false}
        attach="geometry"
        // {...gltf.nodes['blMilMan_m4b_68498-sk_0'].geometry}
        {...gltf.nodes.Cylinder.geometry}
      />
      <meshBasicMaterial attach="material" />
    </instancedMesh>
    // {...gltf.nodes.mesh_0.geometry}
  );
}

function Intro({ start, set }) {
  // const [vec] = useState(() => new THREE.Vector3())
  useEffect(() => setTimeout(() => set(true), 500), [set]);
  // return useFrame((state) => {
  //   if (start) {
  //     state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 5, 8), 0.3)
  //     state.camera.lookAt(0, 0, 0)
  //   }
  // })
  return null;
}

const Ax = () => {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
      <Helmet>
        <title>you know</title>
      </Helmet>
      <Canvas linear flat camera={{ fov: 50, position: [0, 0, 150] }}>
        <Suspense fallback={null}>
          <Intro start={ready && clicked} set={setReady} />
          <Background />
          <DiamondsTwo />
        </Suspense>
      </Canvas>
      <Overlay {...store} />
      <Loader
        containerStyles={{ backgroundColor: 0x090909 }}
        barStyles={{ backgroundColor: 'silver' }}
        dataInterpolation={(p) => `Loading`}
      />
    </>
  );
};

export default Ax;
