import React, { useState, Suspense, useRef, useEffect, useMemo } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  TextureLoader,
  WebGLRenderTarget,
  Object3D,
  LinearFilter
} from "three";
import { Canvas, useFrame, useThree, useLoader } from "react-three-fiber";
import { Loader } from "drei";
import { Helmet } from "react-helmet";
import { initGA, PageView } from "../../components/tracking";
import { Lensflare, LensflareElement } from "./Lensflare.js";
import Overlay from "./Overlay";
import BackfaceMaterial from "./backface-material";
import RefractionMaterial from "./refraction-material";
import textureUrl from "./assets/desert.jpg";
// import diamondUrl from "./assets/diamond.glb"
import lensflare0 from "./assets/lensflare0.png";
import lensflare3 from "./assets/lensflare3.png";

function Background() {
  const { viewport, aspect, gl } = useThree();
  const texture = useLoader(TextureLoader, textureUrl);
  useMemo(() => (texture.minFilter = LinearFilter), [texture.minFilter]);
  // Calculates a plane filling the screen similar to background-size: cover
  const adaptedHeight =
    5000 *
    (aspect > 5000 / 3800 ? viewport.width / 5000 : viewport.height / 3800);
  const adaptedWidth =
    10000 *
    (aspect > 5000 / 3800 ? viewport.width / 5000 : viewport.height / 3800);
  gl.toneMapping = THREE.NoToneMapping;
  return (
    <mesh layers={1} scale={[adaptedWidth, adaptedHeight, 1]}>
      <planeBufferGeometry attach="geometry" />
      <meshBasicMaterial attach="material" map={texture} depthTest={false} />
    </mesh>
  );
}

// function Model({ clicked }) {
//   // const { scene } = useGLTFLoader('carla-draco.glb', true)
//   const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/diamonds.mp4', crossOrigin: 'Anonymous', loop: true, muted: true }))
//   useEffect(() => void (clicked && video.play()), [video, clicked])
//
//   const [video2] = useState(() => Object.assign(document.createElement('video'), { src: '/trip.mp4', crossOrigin: 'Anonymous', loop: true, muted: true }))
//   useEffect(() => void (clicked && video2.play()), [video2, clicked])
//
//   const group = useRef()
//   const { nodes, materials } = useGLTFLoader('/models/ax/scene.gltf', true);
//
//   useFrame((state) => {
//     group.current.children.forEach((child, index) => {
//       // child.position.y += Math.sin(index * 1000 + state.clock.elapsedTime) / 50;
//       // child.rotation.x +=
//       //   (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 2000;
//       child.rotation.y +=
//         (Math.cos(index * 1000 + state.clock.elapsedTime) * Math.PI) / 3000;
//       // child.rotation.z +=
//       //   (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 4000;
//     });
//   });
//
//   return (
//     // <group ref={group}  dispose={null} scale={[10,10,10]} position={[-1,2,0]}>
//     //   <group rotation={[-Math.PI / 2, 0, 0]}>
//     //     <mesh geometry={nodes.mesh_0.geometry} >
//     //       <meshBasicMaterial toneMapped={true} wireframe={false}>
//     //         <videoTexture attach="map" args={[video2]} encoding={THREE.sRGBEncoding} />
//     //       </meshBasicMaterial>
//     //     </mesh>
//     //   </group>
//     // </group>
//     <group ref={group} dispose={null} scale={[10,10,10]} position={[-1,1,0]}>
//       <group rotation={[-Math.PI / 2, 0, 0]} >
//         <mesh material={materials['Scene_-_Root']} geometry={nodes.mesh_0.geometry} />
//       </group>
//     </group>
//   );
// }

function Diamonds({ position }) {
  const { size, viewport, gl, scene, camera, clock } = useThree();
  const model = useRef();
  const gltf = useLoader(GLTFLoader, "/models/mask/scene.gltf");

  // Create Fbo's and materials
  const [
    envFbo,
    backfaceFbo,
    backfaceMaterial,
    refractionMaterial
  ] = useMemo(() => {
    const envFbo = new WebGLRenderTarget(size.width, size.height);
    const backfaceFbo = new WebGLRenderTarget(size.width, size.height);
    const backfaceMaterial = new BackfaceMaterial();
    const refractionMaterial = new RefractionMaterial({
      envMap: envFbo.texture,
      backfaceMap: backfaceFbo.texture,
      resolution: [size.width, size.height]
    });
    return [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial];
  }, [size]);

  // Create random position data
  const dummy = useMemo(() => new Object3D(), []);
  const diamonds = useMemo(
    () =>
      new Array(150).fill().map((_, i) => ({
        position: [
          i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width,
          40 - Math.random() * 2300,
          -500 + Math.random() * 3000
        ],
        factor: 0.1 + Math.random(),
        direction: Math.random() < 0.5 ? -1 : 1,
        rotation: [
          Math.sin(Math.random()) * Math.PI,
          Math.sin(Math.random()) * Math.PI,
          Math.cos(Math.random()) * Math.PI
        ]
      })),
    [viewport.width]
  );

  // Render-loop
  useFrame(() => {
    // Update instanced diamonds
    diamonds.forEach((data, i) => {
      const t = clock.getElapsedTime();
      data.position[0] -=
        Math.sin(t * Math.random() * data.direction) * data.direction * 1.5;
      data.position[1] -= Math.sin(t) * data.direction * 2;
      if (data.direction === 1 ? data.position[1] < -50 : data.position[1] > 50)
        data.position = [
          i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width,
          50 * data.direction,
          data.position[2]
        ];
      const { position, rotation, factor } = data;
      dummy.position.set(position[0], position[1], position[2]);
      dummy.rotation.set(
        rotation[0] + t * factor,
        rotation[1] + t * factor,
        rotation[2] + t * factor
      );
      dummy.rotation.set(0, 0, 0);
      dummy.scale.set(17 + factor, 17 + factor, 17 + factor);
      dummy.updateMatrix();
      model.current.setMatrixAt(i, dummy.matrix);
    });
    model.current.instanceMatrix.needsUpdate = true;
    // Render env to fbo
    gl.autoClear = false;
    camera.layers.set(1);
    gl.setRenderTarget(envFbo);
    gl.render(scene, camera);
    // Render cube backfaces to fbo
    // camera.layers.set(0)
    // model.current.material = backfaceMaterial
    // gl.setRenderTarget(backfaceFbo)
    // gl.clearDepth()
    // gl.render(scene, camera)
    // Render env to screen
    camera.layers.set(1);
    gl.setRenderTarget(null);
    gl.render(scene, camera);
    gl.clearDepth();
    // Render cube with refraction material to screen
    camera.layers.set(0);
    model.current.material = refractionMaterial;
    gl.render(scene, camera);
  }, 1);

  return (
    <instancedMesh ref={model} args={[null, null, diamonds.length]}>
      <bufferGeometry
        dispose={false}
        attach="geometry"
        {...gltf.nodes["blMilMan_m4b_68498-sk_0"].geometry}
      />
      <meshBasicMaterial attach="material" />
    </instancedMesh>
    // {...gltf.nodes.mesh_0.geometry}
  );
}

// const Face = ({ scale, position }) => {
//   const { gl, scene, camera, size } = useThree()
//   const group = useRef()
//   const { nodes } = useGLTFLoader('/models/mask/scene.gltf', true);
//
//   const [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial] = useMemo(() => {
//     const envFbo = new WebGLRenderTarget(size.width, size.height)
//     const backfaceFbo = new WebGLRenderTarget(size.width, size.height)
//     const backfaceMaterial = new BackfaceMaterial()
//     const refractionMaterial = new RefractionMaterial({ envMap: envFbo.texture, backfaceMap: backfaceFbo.texture, resolution: [size.width, size.height] })
//     return [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial]
//   }, [size])
//
//   useFrame((state) => {
//     group.current.children.forEach((child, index) => {
//       child.position.y += Math.sin(index * 1000 + state.clock.elapsedTime) / 3;
//       child.rotation.x +=
//         (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 2000;
//       child.rotation.y +=
//         (Math.cos(index * 1000 + state.clock.elapsedTime) * Math.PI) / 3000;
//       child.rotation.z +=
//         (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 4000;
//     });
//
//     gl.autoClear = false
//     camera.layers.set(1)
//     gl.setRenderTarget(envFbo)
//     gl.render(scene, camera)
//
//     camera.layers.set(1)
//     gl.setRenderTarget(null)
//     gl.render(scene, camera)
//     gl.clearDepth()
//
//     camera.layers.set(0)
//     group.current.material = refractionMaterial
//     gl.render(scene, camera)
//   }, 1);
//
//   return (
//     <group ref={group} dispose={null} scale={scale} position={position}>
//       <group rotation={[0, 0, 0]} >
//         <group position={[-11.91, 2.52, 1.81]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
//         <group position={[-3.98, 12.19, 9.2]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
//         <group position={[3.41, -3.39, 15.64]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
//         <group position={[0, -54.08, 15]}>
//           <mesh   geometry={nodes['blMilMan_m4b_68498-sk_0'].geometry} material={refractionMaterial} />
//         </group>
//         <group position={[-5.33, 1.05, 5.17]} rotation={[0, -0.41, 0]} scale={[1.7, 1.7, 1.7]} />
//       </group>
//     </group>
//   );
// }

const Lights = () => {
  const { scene, gl } = useThree();
  const textureLoader = new THREE.TextureLoader();

  const textureFlare0 = textureLoader.load(lensflare0);
  const textureFlare3 = textureLoader.load(lensflare3);

  function addLight(h, s, l, x, y, z) {
    const light = new THREE.PointLight(0xffffff, 0.1, 200000000);
    light.color.setHSL(h, s, l);
    light.position.set(x, y, z);
    scene.add(light);

    const lensflare = new Lensflare();
    lensflare.addElement(
      new LensflareElement(textureFlare0, 1800, 0, light.color)
    );
    lensflare.addElement(new LensflareElement(textureFlare3, 60, 0.6));
    lensflare.addElement(new LensflareElement(textureFlare3, 70, 0.7));
    lensflare.addElement(new LensflareElement(textureFlare3, 120, 0.9));
    lensflare.addElement(new LensflareElement(textureFlare3, 70, 1));
    light.add(lensflare);
  }

  addLight(1, 0.9, 0.1, 8000, 0, -5000);
  addLight(0.6, 0.6, 0.6, 0, 0, -5000);
  addLight(0.995, 0.4, 0.9, 8000, 8000, -5000);

  gl.outputEncoding = THREE.sRGBEncoding;
  gl.toneMapping = THREE.NoToneMapping;

  return null;
};

function Intro({ start, set }) {
  // const [vec] = useState(() => new THREE.Vector3())
  useEffect(() => setTimeout(() => set(true), 500), [set]);
  // return useFrame((state) => {
  //   if (start) {
  //     state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 5, 8), 0.3)
  //     state.camera.lookAt(0, 0, 0)
  //   }
  // })
  return null;
}

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement }
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame(state => {
    controls.current.update();
    camera.position.z += Math.sin(state.clock.getElapsedTime() * 0.1);
  });
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      // autoRotate={true}
      enableZoom={false}
      enabled={false}
    />
  );
};

const Ax = () => {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
      <Helmet>
        <title>cracks appearing</title>
      </Helmet>
      <Canvas
        camera={{ position: [0, 0, 1500], fov: 60, near: 1, far: 100000 }}
        gl={{ antialias: true, alpha: true }}
      >
        <Suspense fallback={null}>
          <Intro start={ready && clicked} set={setReady} />
          <Background />
          <Diamonds />
          {/* <Face scale={[15,15,15]} position={[-200,10,500]}/>
        <Face scale={[13,13,13]} position={[100,-100,800]}/> */}
          <Lights />
        </Suspense>
        <CameraControls />
      </Canvas>
      <Overlay {...store} />
      <Loader
        containerStyles={{ backgroundColor: 0x090909 }}
        barStyles={{ backgroundColor: "silver" }}
        dataInterpolation={p => `Loading`}
      />
    </>
  );
};

export default Ax;
