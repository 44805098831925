import * as THREE from "three";
import React, { Suspense, useRef, useEffect, useState, useMemo } from "react";
import { Canvas, useFrame, useResource } from "react-three-fiber";
import { PositionalAudio, OrbitControls, Loader } from "drei";
import MultiRef from "react-multi-ref";
import { Helmet } from "react-helmet";
import { initGA, PageView } from "../../components/tracking";

import Overlay from "../../components/util/Overlay";
import "./Sound.css";

function Analyzer({ sound, count, wire }) {
  // <Analyzer /> will not run before everything else in the suspense block is resolved.
  // That means <PositionalAudio/>, which executes async, is ready by the time we're here.
  // The next frame (useEffect) is guaranteed(!) to access positional-audios ref.
  const mesh = useResource();
  const analyser = useRef();
  useEffect(
    () => void (analyser.current = new THREE.AudioAnalyser(sound.current, 32)),
    [sound]
  );

  let _itemRefs = new MultiRef();
  useFrame(state => {
    if (analyser.current) {
      const data = analyser.current.getAverageFrequency();

      _itemRefs.map.forEach(mesh => {
        mesh.material.color.setRGB(
          Math.floor((Math.random() * data * 9) / 30),
          0,
          data / 19
        );
        mesh.rotation.z += Math.sin(
          (state.clock.getElapsedTime() * data * 1) / 2
        );
        mesh.scale.x = mesh.scale.y = mesh.scale.z = (data / 100) * 15;
      });
    }
  });

  const data = useMemo(() => {
    return new Array(count).fill().map((el, i) => ({
      x: Math.random() * 400 - 300,
      y: Math.random() * 300 - 150,
      z: Math.random() * 150 - 100
      // s: Math.random() * 20 + 1,
    }));
  }, [count]);
  return data.map((props, i) => {
    return (
      <mesh
        ref={_itemRefs.ref(i)}
        position={[props.x, props.y, props.z]}
        key={i}
        scale={[3, 3, 3]}
      >
        <ringGeometry attach="geometry" args={[1, 3, 16]} />
        <meshStandardMaterial wireframe={wire} />
      </mesh>
    );
  });
}

function PlaySound({ url }) {
  // This component creates a suspense block, blocking execution until
  // all async tasks (in this case PositionAudio) have been resolved.
  const sound = useRef();

  return (
    <Suspense fallback={null}>
      <PositionalAudio url={url} ref={sound} detune={100} />
      <Analyzer sound={sound} count={150} wire={true} />
    </Suspense>
  );
}

const Sound = () => {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  useEffect(() => {
    setTimeout(() => setReady(true), 500);
  });

  return (
    <>
      <Helmet>
        <title>this time</title>
      </Helmet>
      <Canvas
        concurrent
        camera={{ position: [0, 0, 19] }}
        style={{ background: "#090909" }}
      >
        <ambientLight />
        {ready && <PlaySound url="dawn.mp3" setReady={setReady} />}
        <OrbitControls
          maxDistance={50}
          enablePan={false}
          enableRotate={false}
        />
      </Canvas>
      {/* <Loader
        containerStyles={{ backgroundColor: 0x090909 }}
        barStyles={{ backgroundColor: "silver" }}
        dataInterpolation={p => `Loading`}
      /> */}
      <Overlay {...store}>
        <div onClick={() => ready && setClicked(true)}>
          {!ready ? "loading" : "click to continue"}
        </div>
        <span className="audio-note">
          <span style={{ color: "greenyellow" }}>!important</span>
          <br />
          ***** An Audio track will play by clicking above *****
        </span>
      </Overlay>
    </>
  );
};

export default Sound;
