import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useThree, useFrame } from 'react-three-fiber';
import { OrbitControls } from 'drei'
import { vshader, fshader } from './shaders';
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';
import './Shaders.css';

const Box = () => {
  const {
    size,
    clock,                        // THREE.Clock (useful for useFrame deltas)
  } = useThree();

  const { width, height } = size;

  const uniforms = {
    u_color: { value: new THREE.Color(0xFF0000) },
    u_time: { value: 0.0 },
    u_mouse: { value: { x: 0.0, y: 0.0 } },
    u_resolution: { value: { x: 0.0, y: 0.0 } }
  }

  const cubeRef = useRef();

  // store mouse position and pass it to shader on mouse or touch move
  const move = (e) => {
    uniforms.u_mouse.value.x = (e.touches) ? e.touches[0].clientX : e.clientX;
    uniforms.u_mouse.value.y = (e.touches) ? e.touches[0].clientY : e.clientY;
  }

  useEffect(() => {
    uniforms.u_resolution.value.x = width;
    uniforms.u_resolution.value.y = height;
    if ('ontouchstart' in window) {
      document.addEventListener('touchmove', move);
    } else {
      document.addEventListener('mousemove', move);
    }

    return () => {
      if ('ontouchstart' in window) {
        document.removeEventListener('touchmove', move);
      } else {
        document.removeEventListener('mousemove', move);
      }
    }

  });

  useFrame(() => {
    uniforms.u_time.value = clock.elapsedTime;
    cubeRef.current.rotation.z = cubeRef.current.rotation.x += 0.001;
  })

  return (
    <mesh  ref={cubeRef}>
      <boxBufferGeometry attach="geometry" />
      <shaderMaterial
        side={THREE.DoubleSide}
        vertexShader={vshader}
        fragmentShader={fshader}
        uniforms={uniforms}
      />
    </mesh>
  );
}

const Shaders = () => {
  useEffect(() => {
    initGA();
    PageView();
  });


  return (
    <>
    <Helmet>
        <title>it's blue 🔹</title>
    </Helmet>
    <Canvas style={{ backgroundColor: '#090909' }} camera={{ position: [0,0, 2] }}>
      <Box />
      <pointLight />
      <OrbitControls maxPolarAngle={Math.PI * 0.495}  maxDistance={20.0}/>
    </Canvas>
    </>
  );
}

export default Shaders;
