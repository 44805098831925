import React, { useRef, Suspense, useEffect } from 'react';
import {initGA, PageView} from './tracking';
import { Canvas } from 'react-three-fiber';

import { softShadows, OrbitControls, useGLTFLoader, Loader } from 'drei';
import {Helmet} from 'react-helmet';
softShadows();

function Model({ url }) {
  const ref = useRef();
  // useFrame(() => ( ref.current.rotation.x += 0.005));
  const gltf = useGLTFLoader(url, true);
  return (<mesh position={[0, 0, 5]} ref={ref} rotation={[0.1, 0.5, 0]} >
    <primitive object={gltf.scene} dispose={null} scale={[5,5,5]} />
  />
  </mesh>);
}

// const Lights = () => {
//   return (
//     <>
//       {/* Ambient Light illuminates lights for all objects */}
//       <ambientLight intensity={0.3} />
//       <Lights />
//       {/* Diretion light */}
//       <directionalLight position={[10, 10, 5]} intensity={1} />
//       <directionalLight
//         castShadow
//         position={[0, 100, 0]}
//         intensity={1.5}
//         shadow-mapSize-width={1024}
//         shadow-mapSize-height={1024}
//         shadow-camera-far={50}
//         shadow-camera-left={-10}
//         shadow-camera-right={10}
//         shadow-camera-top={10}
//         shadow-camera-bottom={-10}
//       />
//       {/* Spotlight Large overhead light */}
//       <spotLight intensity={1} position={[1000, 0, 0]} castShadow />
//     </>
//   );
// };

const Dreaming = () => {
  useEffect(() => {
    initGA();
    PageView();
  }, []);
  return (
    <>
      <Helmet>
          <title>score59 | in her heart &#60;3</title>
          <meta name="description" content="experimental developer" />
      </Helmet>
      <Canvas
        concurrent
        shadowMap
        colorManagement
        camera={{ position: [0, 0, 75], fov: 90 }}
        id="home-c"
      >
        <Suspense fallback={null}>
          <Model url="/scene-2.gltf" />
        </Suspense>
        <OrbitControls enableZoom={false} maxDistance={100} minDistance={50}/>
      </Canvas>
      <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
      <a id="model-credit" href="https://sketchfab.com/snorriholm">model credit: snorriholm</a>
    </>
  );
};

export default Dreaming;
