export const vshader = `
varying vec2 v_uv;
void main() {
  v_uv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

export const fshader = `
uniform float u_time;
uniform vec2 u_mouse;
uniform vec2 u_resolution;
uniform vec3 u_color;

varying vec2 v_uv;

void main() {
  vec2 v = u_mouse / u_resolution;
  // vec3 color = vec3(v.x, 0.0, v.y);
  //vec3 color = vec3((sin(u_time) + 1.0)/2.0, (sin(u_time) + cos(u_time)), (cos(u_time) + 1.0)/2.0);
  //vec2 uv = gl_FragCoord.xy / u_resolution;
  //vec3 color = mix(vec3((sin(u_time) + 1.0)/2.0, 0.0 , (cos(u_time) + 1.0)/2.0), vec3(0.0, 0.0, 1.0), uv.y);
  vec3 color = vec3(v_uv.x * v.x, v_uv.y * v.y, (sin(u_time) + cos(u_time)));
  gl_FragColor = vec4(color, 1.0);
}
`
