import React, { useRef, Suspense, useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import { OrbitControls, Loader, useGLTFLoader } from "drei";
import lerp from "lerp";

import icon from "./rose-2.svg";
import "./index.css";

const Robot = props => {
  const group = useRef();

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  useFrame(() => {
    if (props.progress > 0 && props.progress < 1) {
      group.current.rotation.y = Math.PI * props.progress * 2;
    }
  });

  const { nodes, materials } = useGLTFLoader("/models/robot/scene.gltf", true);
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[0, 0.02, 2.36]} scale={[0.88, 0.67, 0.83]}>
          <group position={[0.67, -0.04, -1.62]} scale={[0.19, 0.39, 0.72]}>
            <group
              position={[0.77, -0.9, -1.25]}
              rotation={[0, -0.11, 0.04]}
              scale={[2.39, 2.66, 0.38]}
            >
              <mesh
                geometry={nodes.Foot_Left_1.geometry}
                material={nodes.Foot_Left_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Leg_Left_1.geometry}
              material={nodes.Leg_Left_1.material}
            />
          </group>
          <group position={[-0.68, 0, -1.51]} scale={[0.19, 0.39, 0.72]}>
            <group
              position={[-0.57, -1.02, -1.41]}
              rotation={[0, -0.11, 0.04]}
              scale={[2.39, 2.66, 0.38]}
            >
              <mesh
                geometry={nodes.Foot_Right_1.geometry}
                material={nodes.Foot_Right_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Leg_Right_1.geometry}
              material={nodes.Leg_Right_1.material}
            />
          </group>
          <group position={[0, -0.06, 1.18]} scale={[0.83, 1.1, 1.08]}>
            <group position={[0, 0.02, 1.55]} scale={[2.15, 1.45, 1.35]}>
              <group position={[-1.14, -0.19, 2.1]} scale={[0.05, 0.08, 0.07]}>
                <mesh
                  geometry={nodes.Antenna_Right_Top_1.geometry}
                  material={nodes.Antenna_Right_Top_1.material}
                />
              </group>
              <group position={[-1.03, -0.19, 1.19]} scale={[0.63, 0.94, 0.83]}>
                <mesh
                  geometry={nodes.Antenna_Right_1.geometry}
                  material={nodes.Antenna_Right_1.material}
                />
              </group>
              <group
                position={[-0.46, -1.02, 0.13]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[1.38, 1.79, 0.94]}
              >
                <mesh
                  geometry={nodes.Eye_Right_1.geometry}
                  material={nodes.Eye_Right_1.material}
                />
              </group>
              <group
                position={[0.47, -1.02, 0.13]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[1.02, 1.32, 0.94]}
              >
                <mesh
                  geometry={nodes.Eye_Left_1.geometry}
                  material={nodes.Eye_Left_1.material}
                />
              </group>
              <group position={[1.08, -0.2, 1.96]} scale={[0.05, 0.08, 0.07]}>
                <mesh
                  geometry={nodes.Antenna_Left_Top_1.geometry}
                  material={nodes.Antenna_Left_Top_1.material}
                />
              </group>
              <group
                position={[0, -0.21, 0.11]}
                rotation={[0, Math.PI / 2, 0]}
                scale={[1.32, 1.51, 1.01]}
              >
                <mesh
                  geometry={nodes.Ears_1.geometry}
                  material={nodes.Ears_1.material}
                />
              </group>
              <mesh
                geometry={nodes.Head_1.geometry}
                material={nodes.Head_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Neck_1.geometry}
              material={nodes.Neck_1.material}
            />
          </group>
          <group
            position={[0, -0.89, -0.29]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.28, 0.3, 0.37]}
          >
            <mesh
              geometry={nodes.Pipes_1.geometry}
              material={nodes.Pipes_1.material}
            />
          </group>
          <group
            position={[0, 0.07, 0.18]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            scale={[2.93, 2.34, 1.51]}
          >
            <group
              position={[0, -0.05, -1.22]}
              rotation={[0, 0, -Math.PI / 2]}
              scale={[0.08, 0.08, 0.44]}
            >
              <group
                position={[1.9, 0, -1.3]}
                rotation={[1.17, 0.09, 1.69]}
                scale={[0.35, 1.37, 0.6]}
              >
                <mesh
                  geometry={nodes.Hand_Right_1.geometry}
                  material={nodes.Hand_Right_1.material}
                />
              </group>
              <mesh
                geometry={nodes.Arm_Right_1.geometry}
                material={nodes.Arm_Right_1.material}
              />
            </group>
            <group
              position={[0, -0.05, 1.22]}
              rotation={[0, 0, -Math.PI / 2]}
              scale={[0.08, 0.08, 0.44]}
            >
              <group
                position={[1.99, 0, 1.31]}
                rotation={[1.17, 0.09, 1.69]}
                scale={[0.35, 1.37, 0.6]}
              >
                <mesh
                  geometry={nodes.Hand_Left_1.geometry}
                  material={nodes.Hand_Left_1.material}
                />
              </group>
              <mesh
                geometry={nodes.Arm_Left_1.geometry}
                material={nodes.Arm_Left_1.material}
              />
            </group>
            <mesh
              geometry={nodes.Shoulders_1.geometry}
              material={nodes.Shoulders_1.material}
            />
          </group>
          <mesh
            geometry={nodes.Body_1.geometry}
            material={nodes.Body_1.material}
          />
        </group>
        <group
          position={[-6.08, -14.65, 10.98]}
          rotation={[0.96, -0.33, -0.95]}
          scale={[1, 1, 1]}
        />
        <group
          position={[15.73, 3.3, 10.98]}
          rotation={[-0.83, 1.19, -1.81]}
          scale={[1, 1, 1]}
        />
      </group>
    </group>
  );
};

const Magic = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const renderDivs = num => {
    const colors = ["green", "blue", "pink"];
    return Array(num)
      .fill(0)
      .map((_, i) => {
        return (
          <div
            key={i}
            className={`square ${
              colors[Math.floor(Math.random() * colors.length)]
            }`}
          ></div>
        );
      });
  };

  return (
    <>
      <Canvas camera={{ position: [0, 0.2, 3] }}>
        <Suspense fallback={null}>
          <Robot
            scale={[0.3, 0.3, 0.3]}
            position={[0, -1, 0]}
            progress={scrollProgress}
          />
        </Suspense>
        {/* <OrbitControls /> */}
        <ambientLight intensity={0.1} />
        <pointLight position={[0, 5, 5]} intensity={1.1} color="#badcf2" />
      </Canvas>
      <Loader />
      <div className="magic-ctn">
        <Controller>
          <Scene duration={900} pin={false} indicators={true} offset={0}>
            <Tween
              from={{ y: -700, opacity: 0 }}
              to={{
                opacity: 1,
                rotation: "random(90, 270, 15)",
                x: "random(-120, 200, 15)",
                y: "random(-500, 1000, 50)"
              }}
              stagger={{
                // each: 0.4,
                from: "center",
                grid: "auto",
                amount: 12.2,
                repeat: 5,
                yoyo: false
              }}
            >
              {renderDivs(450)}
            </Tween>
          </Scene>
          <div
            className="xap"
            style={{
              paddingTop: "500px",
              width: "100%",
              height: "100px",
              position: "absolute",
              top: "1200px",
              color: "#fff"
            }}
          >
            <Scene
              duration={800}
              classToggle="zap"
              indicators={true}
              offset={0}
            >
              {(progress, event) => {
                setScrollProgress(progress);
                return (
                  <div className="test">
                    Pin Test {event.type} {progress}
                  </div>
                );
              }}
            </Scene>
          </div>
          {/* <div
            className="hor-div"
            style={{
              height: "auto",
              textAlign: "center",
              position: "absolute",
              top: "2100px",
              color: "#fff"
            }}
          >
            <Scene duration={1000} pin={true} indicators={true} offset={0}>
              <Timeline
                target={<img src={icon} alt="Red Rose" className="red-rose" />}
              >
                <Tween
                  from={{ x: "2000px", opacity: 0, rotation: "360" }}
                  to={{ x: "700", opacity: 0.6, rotation: "0" }}
                  duration={1}
                />
                <Tween
                  to={{
                    x: "400",
                    opacity: 1,
                    scale: 0.6,
                    y: "-200"
                  }}
                  duration={0.5}
                />
                <Tween
                  to={{ x: "500px", scale: 3, rotation: 360 }}
                  duration={2}
                />
              </Timeline>
            </Scene>
          </div> */}
        </Controller>
      </div>
    </>
  );
};

export default Magic;
