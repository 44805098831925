import React, { Suspense, useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import * as THREE from 'three';
import { OrbitControls, useGLTFLoader, Loader, Stars, PositionalAudio } from "drei";
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';


// function Model({speed, factor, play}) {
//   // const group = useRef()
//   // const { nodes, materials } = useGLTFLoader('/scene.gltf', true)
//   const group = useRef()
//   const { nodes, materials, animations } = useGLTFLoader('kick.glb', true);
//
//   const [mixer] = useState(() => new THREE.AnimationMixer())
//
//   useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
//   useFrame((state, delta) => {
//     if (play) {
//       mixer.update(0.006);
//     }
//     // mixer._actions[0].paused = true
//   })
//
//   return (
//     <group ref={group} dispose={null} position={[0,-8,2]} >
//       <group rotation={[Math.PI / 2, 0, 0]} scale={[0.1, 0.1, 0.1]} >
//         <primitive object={nodes.mixamorigHips} />
//         <skinnedMesh
//           material={materials.Ch03_Body}
//           geometry={nodes.Ch03.geometry}
//           skeleton={nodes.Ch03.skeleton}
//         />
//       </group>
//     </group>
//   )
// }

function Dance(props) {
  // const group = useRef()
  // const { nodes, materials } = useGLTFLoader('/scene.gltf', true)
  const group = useRef()
  const { nodes, materials, animations } = useGLTFLoader('dance.glb', true);

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
  useFrame((state, delta) => {
    if (props.play) {
      mixer.update(0.011);
    }
    // mixer._actions[0].paused = true
  })

  return (
    <group ref={group} {...props} dispose={null} position={[0,-8,-15]}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.1, 0.1, 0.1]}>
        <primitive object={nodes.Hips} />
        <skinnedMesh
          material={materials.WhiteClown_material}
          geometry={nodes.WhiteClown.geometry}
          skeleton={nodes.WhiteClown.skeleton}
        />
      </group>
    </group>
  )
}

function Belly(props) {
  // const group = useRef()
  // const { nodes, materials } = useGLTFLoader('/scene.gltf', true)
  const group = useRef()
  const { nodes, materials, animations } = useGLTFLoader('belly.glb', true);

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
  useFrame((state, delta) => {
    if (props.play) {
      mixer.update(0.011);
    }
    // mixer._actions[0].paused = true
  })

  return (
    <group ref={group} {...props} dispose={null} position={[7,-8,0]}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.07, 0.07, 0.07]}>
        <primitive object={nodes.Hips} />
        <skinnedMesh
          material={materials.Girl01_Body_MAT1}
          geometry={nodes.Girl_Body_Geo.geometry}
          skeleton={nodes.Girl_Body_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Girl01_Brows_MAT1}
          geometry={nodes.Girl_Brows_Geo.geometry}
          skeleton={nodes.Girl_Brows_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Girl01_Eyes_MAT1}
          geometry={nodes.Girl_Eyes_Geo.geometry}
          skeleton={nodes.Girl_Eyes_Geo.skeleton}
          castShadow receiveShadow
        />
        <skinnedMesh
          material={materials.Girl01_Mouth_MAT1}
          geometry={nodes.Girl_Mouth_Geo.geometry}
          skeleton={nodes.Girl_Mouth_Geo.skeleton}
        />
      </group>
    </group>
  )
}

function Dude(props) {
  // const group = useRef()
  // const { nodes, materials } = useGLTFLoader('/scene.gltf', true)
  const group = useRef()
  const { nodes, materials, animations } = useGLTFLoader('dude.glb', true);

  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
  useFrame((state, delta) => {
    if (props.play) {
      mixer.update(0.009);
    }
  })

  return (
    <group ref={group} {...props} dispose={null} position={[-7,-8,0]}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[0.07, 0.07, 0.07]}>
        <primitive object={nodes.bossHips} />
        <skinnedMesh
          material={materials.Skin_MAT}
          geometry={nodes.bossArms_Geo.geometry}
          skeleton={nodes.bossArms_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Cigar_Mat}
          geometry={nodes.bossCigar_Geo.geometry}
          skeleton={nodes.bossCigar_Geo.skeleton}
        />
        <skinnedMesh
          material={materials['Clothes_MAT.001']}
          geometry={nodes.bossHat_Geo.geometry}
          skeleton={nodes.bossHat_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Skin_MAT}
          geometry={nodes.bossHead_Geo.geometry}
          skeleton={nodes.bossHead_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Clothes_MAT}
          geometry={nodes.bossJacket_Geo.geometry}
          skeleton={nodes.bossJacket_Geo.skeleton}
        />
        <skinnedMesh
          material={materials['Eyes_MAT.001']}
          geometry={nodes.bossL_Eye_Geo.geometry}
          skeleton={nodes.bossL_Eye_Geo.skeleton}
        />
        <skinnedMesh
          material={materials['Clothes_MAT.001']}
          geometry={nodes.bossPants_Geo.geometry}
          skeleton={nodes.bossPants_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Eyes_MAT}
          geometry={nodes.bossR_Eye_Geo.geometry}
          skeleton={nodes.bossR_Eye_Geo.skeleton}
        />
        <skinnedMesh
          material={materials['Clothes_MAT.001']}
          geometry={nodes.bossShoes_Geo.geometry}
          skeleton={nodes.bossShoes_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Skin_MAT}
          geometry={nodes.bossTeeth_Down_Geo.geometry}
          skeleton={nodes.bossTeeth_Down_Geo.skeleton}
        />
        <skinnedMesh
          material={materials.Skin_MAT}
          geometry={nodes.bossTeeth_Up_Geo.geometry}
          skeleton={nodes.bossTeeth_Up_Geo.skeleton}
        />
      </group>
    </group>
  )
}

const Mountain = (props) => {
  const group = useRef()
  const { nodes, materials } = useGLTFLoader('mountain.gltf', true);


  return (
    <group ref={group} {...props} dispose={null} scale={[32, 32, 32]} position={[0,-8,0]}>
      <group rotation={[-Math.PI / 2, 0, props.z]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group scale={[8, 8, 8]}>
            <mesh material={materials['Material.001']} geometry={nodes.mesh_0.geometry} />
          </group>
          <group scale={[8, 8, 8]}>
            <mesh material={materials['Material.002']} geometry={nodes.mesh_1.geometry} />
          </group>
        </group>
      </group>
    </group>
  )
}

// const Player = () => {
//   const listener = new THREE.AudioListener();
//   const sound = new THREE.Audio( listener );
//
//   const audioLoader = new THREE.AudioLoader();
//   audioLoader.load( 'affair.mp3', function( buffer ) {
//   	sound.setBuffer( buffer );
//   	sound.setLoop( true );
//   	sound.setVolume( 0.5 );
//   	sound.play();
//   });
//   return null;
// }

const Animation = () => {
  const [play,setPlay] = useState(false);
  useEffect(() => {
    initGA();
    PageView();
    // audioPlayer.current.isPlaying = false;
  }, []);

  // const listener = new THREE.AudioListener();
  // const sound = new THREE.Audio( listener );
  //
  // const audioLoader = new THREE.AudioLoader();
  //
  // audioLoader.load( 'affair.mp3', function( buffer ) {
  //   sound.setBuffer( buffer );
  //   sound.setLoop( true );
  //   sound.setVolume( 0.5 );
  // });
  //
  const handleClick = () => {
    setPlay(!play);
  }

  const renderAudio = () => {
    if (!play) {
      return null;
    }
    return (
      <Suspense fallback={null} >
        <PositionalAudio url="affair.mp3" position={[0,0, 18]} ref={audioPlayer} />
      </Suspense>
    );
  }

  const audioPlayer = useRef();

  return (
    <>
    <Helmet>
        <title>ooh aah ooh aah</title>
    </Helmet>
    <Canvas style={{ background: '#090909' }} camera={{position: [0,2,18]}}>
      <OrbitControls />
      <ambientLight intensity={0.3} />
      <directionalLight intensity={0.6} />
      <spotLight
        castShadow
        intensity={0.89}
        angle={0.7}
        penumbra={1}
        position={[0, 3, 1]}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-bias={-0.0001}
      />
      <fog attach="fog" args={['cyan', 16, 530]} />
      <Suspense fallback={null}>
        {/* <Model speed={0.5} factor={0.5} play={play}/> */}
        <Mountain z={180}/>
        <Mountain z={0}/>
        <Dance play={play}/>
        <Belly play={play}/>
        <Dude play={play}/>
      </Suspense>
      {renderAudio()}
      <Stars
        radius={100} // Radius of the inner sphere (default=100)
        depth={100} // Depth of area where stars should fit (default=50)
        count={5000} // Amount of stars (default=5000)
        factor={4} // Size factor (default=4)
        saturation={1} // Saturation 0-1 (default=0)
        fade // Faded dots (default=false)
      />
    </Canvas>
    <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    <button className="sound-btn" onClick={() => handleClick()}>{play ? 'pause' : 'play'}</button>
    </>
  );
}

export default Animation;
