import React, { useRef, useState, useMemo, Suspense, useEffect } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import {OrbitControls, PositionalAudio, RoundedBox} from 'drei';
import {Helmet} from 'react-helmet';
import {initGA, PageView} from './tracking';

import './css/Heart.css';

const Sphere = ({ geometry, x, y, z, s }) => {
  const [active, setActive] = useState(false);
  const ref = useRef();

  useFrame((state) => {
    ref.current.position.x = x + Math.sin((state.clock.getElapsedTime() * s) / 2)
    ref.current.position.y = y + Math.sin((state.clock.getElapsedTime() * s) / 2)
    ref.current.position.z = z + Math.sin((state.clock.getElapsedTime() * s) / 0.6)
    ref.current.rotation.z = ref.current.rotation.y =ref.current.rotation.x += 0.01;
  });

  return (
    <mesh
      ref={ref}
      position={[x,y,z]}
      scale={[s,s,s]}
      geometry={geometry}
      onClick={() => setActive(!active)}
    >
      <meshStandardMaterial color={active ? "cornflowerblue" : "white"} roughness={1} metalness={0.7} />

    </mesh>
  );
}

function RandomSpheres() {
  const [geometry] = useState(() => new THREE.IcosahedronBufferGeometry(1, 10), [])
  const data = useMemo(() => {
    return new Array(100).fill().map((_, i) => ({
      x: Math.random() * 200 - 100,
      y: Math.random() * 140 - 70,
      z: Math.random() * 300 - 150,
      s: Math.random() + 8
    }))
  }, [])
  return data.map((props, i) => <Sphere key={i} {...props} geometry={geometry} />)
}

const Kiara = ({ soundActive, setSoundActive }) => {

  const color1 = new THREE.Color("rgb(0,0,0)");

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  const renderSound = () => {
    if (soundActive) {
      return <PositionalAudio url="/lova.mp3" />;
    }
  }


  return (
    <>
    <Helmet>
        <title>your artichoke ♥</title>
    </Helmet>
    <button className="sound-btn" onClick={() => setSoundActive(!soundActive)}>{soundActive ? 'pause sound' : 'play sound'}</button>
    <Canvas camera={{ position: [0, 0, 200], far:50000 }} >
      <color attach="background" args={['black']} />
      <ambientLight intensity={0.2} />
      <pointLight intensity={1}/>
      <pointLight position={[0, 60, -100]} intensity={0.8} />
      <pointLight position={[-50, 0, -50]} intensity={0.8} />
      <RandomSpheres/>
      <Suspense fallback={null}>
        <mesh>
          <boxBufferGeometry attach="geometry" />
          <meshPhongMaterial attach="material" color={color1} transparent={true} opacity={0.0001} />
          {renderSound()}
        </mesh>
      </Suspense>
      <RoundedBox
        args={[400,400,400]}
        smoothness={2}
        radius={400}
      >
        <meshPhongMaterial attach="material" color="lightslategray" wireframe />
      </RoundedBox>
      <OrbitControls zoomSpeed={0.6} maxZoom={400} maxDistance={400}/>
    </Canvas>
    <a id="model-credit" href="https://freemusicarchive.org/music/Komiku/Poupis_incredible_adventures_/Komiku_-_Poupis_incredible_adventures__-_32_Love_Planet">music credit: Komiku</a>
    </>
  );
}

export default Kiara;
