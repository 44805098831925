import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';

const Hand = () => {
  const myRef = useRef();

  useEffect(() => {
    initGA();
    PageView();
  });

  let scene, camera, renderer;

  let mesh;
  const amount = 6;
  const count = Math.pow(amount, 3);
  const dummy = new THREE.Object3D();

  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    0.1,
    10000
  );
  camera.position.set(amount * 0.9, amount * 0.9, 4);
  camera.lookAt(0, 0, 0);

  scene = new THREE.Scene();

  const loader = new GLTFLoader();
  loader.load('/models/hand2/scene.gltf', (gltf) => {
    const geometries = [];
    gltf.scene.traverse((child) => {
      if (child.geometry) {
        geometries.push(child.geometry);
      }

      const material = new THREE.MeshNormalMaterial();
      material.wireframe = true;
      // material.color = new THREE.Color('#7CE6C6');

      if (geometries[0]) {
        geometries[0].computeVertexNormals();
      }

      mesh = new THREE.InstancedMesh(geometries[0], material, count);
      mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage); // will be updated every frame
      mesh.scale.set(0.5, 0.5, 0.5);
      scene.add(mesh);
    });
  });

  scene.add(new THREE.AmbientLight());

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  function animate() {
    requestAnimationFrame(animate);

    render();
  }

  function render() {
    if (mesh) {
      const time = Date.now() * 0.001;

      mesh.rotation.x = Math.sin(time / 4);
      mesh.rotation.y = Math.sin(time / 2);

      let i = 0;
      const offset = amount - 1;

      for (let x = 0; x < amount; x++) {
        for (let y = 0; y < amount; y++) {
          for (let z = 0; z < amount; z++) {
            dummy.position.set(offset - x, offset - y, offset - z);
            dummy.rotation.y =
              Math.sin(x / 4 + time) +
              Math.sin(y / 4 + time) +
              Math.sin(z / 4 + time);
            dummy.rotation.z = dummy.rotation.y * 2;

            dummy.updateMatrix();

            mesh.setMatrixAt(i++, dummy.matrix);
          }
        }
      }

      mesh.instanceMatrix.needsUpdate = true;
    }
    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>that takes</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
};

export default Hand;
