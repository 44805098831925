import ReactGA from 'react-ga4';

const measurement_id = 'G-R5NVB5C0W1';
export const initGA = () => {
  ReactGA.initialize(measurement_id);
};

export const PageView = () => {
  return;
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: document.title,
  });
};
