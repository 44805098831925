import React, { useRef, useEffect, Suspense } from 'react';
import {Helmet} from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {initGA, PageView} from './tracking';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import {
  CubeTextureLoader,
  CubeCamera,
  WebGLCubeRenderTarget,
  RGBFormat,
  LinearMipmapLinearFilter,
  sRGBEncoding,
  // TextureLoader,
  // EquirectangularReflectionMapping,
  MultiplyOperation
} from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Loader } from 'drei';

extend({ OrbitControls });

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement }
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={true}
      enableZoom={false}
      enableDamping={true}
      dampingFactor={0.1}
    />
  );
};

const Skybox = () => {
  const { scene, gl } = useThree();
  const loader = new CubeTextureLoader();

  // const textureLoader = new TextureLoader();
  //
  // const texture = textureLoader.load( '/room.jpg', function ( texture ) {
  //
  // 	texture.encoding = sRGBEncoding;
  // 	texture.mapping = EquirectangularReflectionMapping;
  //
  // } );

  const texture = loader.load([
    "/1.png",
    "/2.png",
    "/3.png",
    "/4.png",
    "/5.png",
    "/6.png"
  ]);

  // const texture = loader.load([
  //   'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'
  // ]);

  scene.background = texture;
  gl.outputEncoding = THREE.LinearEncoding;
  gl.toneMapping = THREE.NoToneMapping;
  return null;
}

const Sphere = ({history}) => {
  const { gl, scene } = useThree();

  const sphereRef = useRef();

  const cubeRenderTarget = new WebGLCubeRenderTarget(1024, {
    format: RGBFormat,
    generateMipmaps: true,
    minFilter: LinearMipmapLinearFilter,
    encoding: sRGBEncoding
  });

  const cubeCamera = new CubeCamera(2, 1000, cubeRenderTarget);
  cubeCamera.position.set(0, 0, 0);

  const cubeRenderTarget2 = new WebGLCubeRenderTarget( 256, {
		format: RGBFormat,
		generateMipmaps: true,
		minFilter: LinearMipmapLinearFilter,
		encoding: sRGBEncoding
	} );

	const cubeCamera2 = new CubeCamera( 1, 1000, cubeRenderTarget2 );
  cubeCamera2.position.set(10, 10, 10);

  useFrame(() => {
    cubeCamera.update(gl, scene);
    cubeCamera2.update(gl, scene);
    sphereRef.current.rotation.y = sphereRef.current.rotation.x = sphereRef.current.rotation.z += 0.01;
  });

  return (
    <mesh visible position={[0,0,0]} castShadow ref={sphereRef} onClick={() => history.push("/city")} >
      <directionalLight intensity={1}  />
      <pointLight position={[0, 5, 5]} />
      <sphereGeometry attach="geometry" args={[1,32,32]} />
      <meshBasicMaterial
        attach="material"
        envMap={cubeCamera.renderTarget.texture}
        color={0xffffff}
        roughness={0.5}
        metalness={0}
        combine={MultiplyOperation}
        reflectivity={1}
      />
    </mesh>
  );
}

const Space = () => {
  useEffect(() => {
    initGA();
    PageView();
  }, []);

  const history = useHistory();

  return (
    <>
    <Helmet>
        <title>they're &#127837;</title>
    </Helmet>
    <Canvas>
      <CameraControls />
      <ambientLight />
      <pointLight intensity={2} color="white" />
      <Suspense fallback={null}>
        <Skybox />
      </Suspense>
      <Sphere history={history} />
    </Canvas>
    <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    </>
  );
};

export default Space;
