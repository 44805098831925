import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

import Dreaming from './components/Dreaming';
import Space from './components/Space';
import City from './components/City';
import Kiara from './components/Kiara';
import Phoenix from './components/Phoenix';
import NotFound from './pages/NotFound/NotFound';
import Ascii from './pages/Ascii/Ascii';
import Header from './components/header/Header';
import Playground from './pages/Playground/';
import Sound from './pages/Sound';
import Animation from './pages/Animation';
import Shaders from './pages/Shaders';
import SpriteAnimation from './pages/Sprite';
import Ocean from './pages/Ocean';
import Face from './pages/Face';
// import ScrollShader from "./pages/ScrollShader";
import Reflekt from './pages/Reflector';
import Mask from './pages/Mask';
import Ax from './pages/Ax';
import Dragon from './pages/Dragon';
import Analyzer from './pages/MusicAnalyser';
import NeverLeft from './pages/NeverLeft';
import Summer from './pages/Summer';
import Magic from './pages/Magic';
import NewShaders from './pages/NewShaders';
import Shadows from './pages/Shadows';
import Nine from './pages/Nine';
import Cat from './pages/Cat';
import OneDay from './pages/OneDay';
import Q from './pages/Q';
import Wonder from './pages/Wonder';
import Hand from './pages/Hand';
import Music from './pages/Music';
import Jon from './pages/Jon';
import Lamina from './pages/Lamina';
import Mahsa from './pages/woman';

import MainLink from './components/navigation/MainLink';

const App = () => {
  const [soundActive, setSoundActive] = useState(false);

  return (
    <>
      <Router>
        <Header />
        <MainLink />
        <Switch>
          <Route path="/" exact render={() => <Dreaming />} />
          <Route path="/swinging-door" exact render={() => <Space />} />
          <Route path="/city" exact render={() => <City />} />
          <Route
            path="/evol"
            exact
            render={() => (
              <Kiara
                soundActive={soundActive}
                setSoundActive={setSoundActive}
              />
            )}
          />
          <Route path="/ahead" exact render={() => <Phoenix />} />
          <Route path="/kiara" exact render={() => <Ascii />} />
          <Route path="/where" exact render={() => <Playground />} />
          <Route path="/with-style" exact render={() => <Sound />} />
          <Route path="/honey" exact render={() => <Animation />} />
          <Route path="/versicolor" exact render={() => <Shaders />} />
          <Route path="/not-me" exact render={() => <SpriteAnimation />} />
          <Route path="/here" exact render={() => <Ocean />} />
          <Route path="/truth-ray" exact render={() => <Face />} />
          {/* <Route path="/shader" exact render={() => <ScrollShader />} /> */}
          <Route path="/poison" exact render={() => <Reflekt />} />
          <Route path="/you-again" exact render={() => <Mask />} />
          <Route path="/centre" exact render={() => <Ax />} />
          <Route path="/find-me" exact render={() => <Dragon />} />
          <Route path="/hello" exact render={() => <Analyzer />} />
          <Route path="/never-left" exact render={() => <NeverLeft />} />
          <Route path="/kana" exact render={() => <Summer />} />
          <Route path="/new-shaders" exact render={() => <NewShaders />} />
          <Route path="/shadows" exact render={() => <Shadows />} />
          <Route path="/magic" exact render={() => <Magic />} />
          <Route path="/nine" exact render={() => <Nine />} />
          <Route path="/a-cat" exact render={() => <Cat />} />
          <Route path="/one-day" exact render={() => <OneDay />} />
          <Route path="/q" exact render={() => <Q />} />
          <Route path="/meta" exact render={() => <Wonder />} />
          <Route path="/the-hand" exact render={() => <Hand />} />
          <Route path="/yes" exact render={() => <Music />} />
          <Route path="/no-choice" render={() => <Jon />} />
          <Route path="/for-now" render={() => <Lamina />} />
          <Route path="/mahsa" render={() => <Mahsa />} />
          <Route path="*" render={() => <NotFound />} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
