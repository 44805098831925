import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';

export default function Cat() {
  useEffect(() => {
    initGA();
    PageView();
  });

  const myRef = useRef();

  let scene, renderer;
  let camera, light;
  let vnh;
  // let vth;

  renderer = new THREE.WebGLRenderer();
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  scene = new THREE.Scene();

  camera = new THREE.PerspectiveCamera(
    70,
    window.innerWidth / window.innerHeight,
    1,
    1000
  );
  camera.position.z = 400;

  light = new THREE.PointLight();
  light.position.set(200, 100, 150);
  scene.add(light);

  scene.add(new THREE.PointLightHelper(light, 15));

  const gridHelper = new THREE.GridHelper(400, 40, 0x808080, 0x808080);
  gridHelper.position.y = -150;
  gridHelper.position.x = -150;
  scene.add(gridHelper);

  const polarGridHelper = new THREE.PolarGridHelper(
    200,
    16,
    32,
    64,
    0xf0e272,
    0x808080
  );
  polarGridHelper.position.y = -150;
  polarGridHelper.position.x = 200;
  scene.add(polarGridHelper);

  const loader = new GLTFLoader();
  loader.load('rabbit/Nefertiti.glb', (gltf) => {
    const mesh = gltf.scene.children[0];
    mesh.position.y = -11;
    const group = new THREE.Group();
    group.scale.multiplyScalar(14);
    scene.add(group);

    group.updateMatrixWorld();
    group.add(mesh);

    vnh = new VertexNormalsHelper(mesh, 55, 0x3136eb);
    scene.add(vnh);

    // vth = new VertexTangentsHelper(mesh, 5);
    // scene.add(vth);

    // scene.add(new THREE.BoxHelper(mesh, 0x0000ff));

    const wireframe = new THREE.WireframeGeometry(mesh.geometry);
    let line = new THREE.LineSegments(wireframe);
    line.material.color = new THREE.Color(0xb8f071);
    line.material.depthTest = false;
    line.material.opacity = 0.15;
    line.material.transparent = true;
    line.position.x = 15;
    line.position.y = -11;
    group.add(line);
    // scene.add(new THREE.BoxHelper(line, 0x0000ff));

    const edges = new THREE.EdgesGeometry(mesh.geometry);
    line = new THREE.LineSegments(edges);
    line.material.color = new THREE.Color(0xf0699b);
    line.material.depthTest = false;
    line.material.opacity = 0.15;
    line.material.transparent = true;
    line.position.x = -15;
    line.position.y = -11;
    group.add(line);
    // scene.add(new THREE.BoxHelper(line, 0x0000ff));
  });

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  function animate() {
    requestAnimationFrame(animate);

    const time = -performance.now() * 0.0003;

    camera.position.x = 400 * Math.cos(time);
    camera.position.z = 400 * Math.sin(time);
    camera.lookAt(scene.position);

    light.position.x = Math.sin(time * 1.7) * 300;
    light.position.y = Math.cos(time * 1.5) * 400;
    light.position.z = Math.cos(time * 1.3) * 300;

    if (vnh) vnh.update();
    // if (vth) vth.update();

    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>who fights lions</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
}
