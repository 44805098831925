import React, { useRef, useMemo, Suspense, useEffect } from 'react';
// import {Sky, Plane, Stars} from 'drei';
import { Canvas, useFrame, useThree, extend } from 'react-three-fiber';
import * as THREE from 'three';
import { useGLTFLoader, Loader } from 'drei';
import {Helmet} from 'react-helmet';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { AdditiveBlendingShader, VolumetricLightShader } from './shaders'
// import Model from './Model';
import {initGA, PageView} from './tracking';

import './css/Heart.css';

extend({ EffectComposer, RenderPass, ShaderPass })

const DEFAULT_LAYER = 0
const OCCLUSION_LAYER = 1

function Model({ layer = DEFAULT_LAYER }) {
  // const group = useRef()
  // const { nodes, materials } = useGLTFLoader('/scene.gltf', true)
  const group = useRef()
  const { nodes } = useGLTFLoader('scene-3.gltf', true)

  const material = useMemo(() => {
    if (layer === DEFAULT_LAYER) return new THREE.MeshStandardMaterial({ color: new THREE.Color('#2a2a2a'), roughness: 1, metalness: 0.9 })
    else return new THREE.MeshBasicMaterial({ color: new THREE.Color('black') })
  }, [layer])

  useFrame(() => {
    group.current.rotation.y += 0.004;
  });

  return (
    <group ref={group} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh material={material} geometry={nodes.pCylinder3_Green_Stem_0.geometry} layers={layer} receiveShadow castShadow/>
          <mesh material={material} geometry={nodes.pCylinder3_Red_0.geometry} layers={layer} receiveShadow castShadow/>
        </group>
      </group>
    </group>
  )
}

function Effects() {
  const { gl, scene, camera, size } = useThree()
  const occlusionRenderTarget = useMemo(() => new THREE.WebGLRenderTarget(), [])
  const occlusionComposer = useRef()
  const composer = useRef()

  useEffect(() => {
    occlusionComposer.current.setSize(size.width, size.height)
    composer.current.setSize(size.width, size.height)
  }, [size])

  useFrame(() => {
    camera.layers.set(OCCLUSION_LAYER)
    occlusionComposer.current.render()
    camera.layers.set(DEFAULT_LAYER)
    composer.current.render()
  }, 1)

  return (
    <>
      <mesh layers={OCCLUSION_LAYER} position={[0, 4, -10]}>
        <sphereBufferGeometry args={[5, 32, 32]} />
        <meshBasicMaterial />
      </mesh>
      <effectComposer ref={occlusionComposer} args={[gl, occlusionRenderTarget]} renderToScreen={false}>
        <renderPass attachArray="passes" args={[scene, camera]} />
        <shaderPass attachArray="passes" args={[VolumetricLightShader]} needsSwap={false} />
      </effectComposer>
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" args={[scene, camera]} />
        <shaderPass attachArray="passes" args={[AdditiveBlendingShader]} uniforms-tAdd-value={occlusionRenderTarget.texture} />
        <shaderPass attachArray="passes" args={[FXAAShader]} uniforms-resolution-value={[1 / size.width, 1 / size.height]} renderToScreen />
      </effectComposer>
    </>
  )
}



const Phoenix = () => {
  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
    <Helmet>
        <title>are we not enough?</title>
    </Helmet>
    <Canvas shadowMap style={{ background: '#171717' }} camera={{ position: [0, 0, 12], fov: 50 }} gl={{ antialias: false }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[0, 60, -100]} intensity={20} />
      <pointLight position={[-50, 0, -50]} intensity={2} />
      <spotLight castShadow intensity={8} angle={Math.PI / 10} position={[10, 10, 10]} shadow-mapSize-width={2048} shadow-mapSize-height={2048} />
      <mesh position={[0, 4.5, 10]}>
        <sphereBufferGeometry args={[5.1, 32, 32]} />
        <meshBasicMaterial transparent opacity={0.5} />
      </mesh>
      <Suspense fallback={null}>
        <mesh position={[0,1.5,-1]} scale={[2.5,2.5,2.5]} rotation={[0.8,0,0]}>
          <Model />
          <Model layer={OCCLUSION_LAYER}/>
        </mesh>
      </Suspense>
      <Effects />
    </Canvas>
    <a id="model-credit" href="https://sketchfab.com/mcnubbin">model credit: mcnubbin</a>
    <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    </>
  );
}

export default Phoenix;
