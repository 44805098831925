import React, { useRef, Suspense, useEffect, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from 'react-three-fiber';
import { useGLTFLoader, Loader } from 'drei';
import { Helmet } from 'react-helmet';
import lerp from 'lerp';
import { useMediaQuery } from 'react-responsive';
// import * as dat from "dat.gui";

import { initGA, PageView } from '../../components/tracking';
import Overlay from './Overlay';
import './index.css';

function Model(props) {
  // const { scene } = useGLTFLoader('carla-draco.glb', true)
  const group = useRef();
  const { nodes } = useGLTFLoader('/mother/scene.gltf', true);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 968px)',
  });

  useFrame((state) => {
    if (isDesktopOrLaptop) {
      group.current.rotation.y = lerp(
        group.current.rotation.y,
        state.mouse.x * (Math.PI / 2),
        // 1,
        0.03
      );
    } else {
      group.current.rotation.y += 0.01;
    }
  });

  const standardMaterial = new THREE.MeshStandardMaterial({
    color: '#474747',
    metalness: 0.5,
    roughness: 0.9,
    // wireframe: true
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[0, -1.44, 0]}>
        <group rotation={[-Math.PI / 2, 0, -0.7]}>
          <mesh
            geometry={nodes.mesh_0.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_1.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_2.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_3.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_4.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_5.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_6.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_7.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_8.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_9.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_10.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.mesh_11.geometry}
            material={standardMaterial}
            castShadow
            receiveShadow
          />
        </group>
      </group>
    </group>
  );
}

function Tv({ clicked, url, opacity, ...props }) {
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src: url,
      crossOrigin: 'Anonymous',
      loop: true,
      muted: true,
    })
  );
  useEffect(() => void (clicked && video.play()), [clicked, video]);

  // useEffect(() => {
  //   const gui = new dat.GUI();
  //   const cubeFolder = gui.addFolder("TV");
  //   cubeFolder.add(group.current.position, "x", -20, 20, 0.5);
  //   cubeFolder.add(group.current.position, "y", -20, 20, 0.5);
  //   cubeFolder.add(group.current.position, "z", -20, 20, 0.5);
  //   cubeFolder.add(group.current.rotation, "x", -Math.PI, Math.PI * 2, 0.01);
  //   cubeFolder.add(group.current.rotation, "y", -Math.PI, Math.PI * 2, 0.01);
  //   cubeFolder.add(group.current.rotation, "z", -Math.PI, Math.PI * 2, 0.01);
  //   cubeFolder.open();
  // }, []);

  const group = useRef();
  const { nodes } = useGLTFLoader('/mother/tv/scene.gltf', true);

  const standardMaterial2 = new THREE.MeshStandardMaterial({
    color: '#888989',
    metalness: 0.5,
    roughness: 0.9,
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh geometry={nodes.defaultMaterial.geometry}>
              <meshBasicMaterial
                toneMapped={false}
                opacity={opacity}
                transparent
              >
                <videoTexture
                  attach="map"
                  args={[video]}
                  encoding={THREE.sRGBEncoding}
                />
              </meshBasicMaterial>
            </mesh>
          </group>
          <mesh
            geometry={nodes.defaultMaterial_1.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_2.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_3.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_4.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_5.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
          <mesh
            geometry={nodes.defaultMaterial_6.geometry}
            material={standardMaterial2}
            castShadow
            receiveShadow
          />
        </group>
      </group>
    </group>
  );
}

function Lights() {
  const groupL = useRef();
  const groupR = useRef();
  const front = useRef();

  useFrame(({ mouse }) => {
    groupL.current.rotation.y = lerp(
      groupL.current.rotation.y,
      -mouse.x * (Math.PI / 2),
      0.1
    );
    groupR.current.rotation.y = lerp(
      groupR.current.rotation.y,
      mouse.x * (Math.PI / 2),
      0.1
    );
    front.current.position.x = lerp(
      front.current.position.x,
      mouse.x * 12,
      0.4
    );
    front.current.position.y = lerp(
      front.current.position.y,
      7 + mouse.y * 4,
      0.4
    );
  });

  return (
    <>
      <group ref={groupL}>
        <pointLight
          position={[0, -3, -15]}
          distance={16}
          intensity={2.5}
          color="red"
        />
      </group>
      <group ref={groupR}>
        <pointLight
          position={[0, 3, -15]}
          distance={16}
          intensity={2.5}
          color="blue"
        />
      </group>
      <spotLight
        castShadow
        ref={front}
        penumbra={1}
        angle={Math.PI / 3}
        position={[0, 0, 8]}
        distance={10}
        intensity={4}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </>
  );
}

function Intro({ start, set }) {
  useEffect(() => setTimeout(() => set(true), 500), [set]);
  return null;
}

const NeverLeft = () => {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  const store = { clicked, setClicked, ready, setReady };

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
      <Helmet>
        <title>right here</title>
      </Helmet>
      <Canvas
        camera={{ position: [0, -2, 20], fov: 50, near: 1, far: 40 }}
        className="mother-ctn"
      >
        <fog attach="fog" args={['black', 0, 40]} />
        <pointLight position={[0, 5, -10]} intensity={0.1} />
        {/* <ambientLight intensity={1} /> */}
        <Suspense fallback={null}>
          <Model
            position={[-0.4, -13, 2]}
            scale={[0.45, 0.45, 0.45]}
            // rotation={[0, 1.5, 0]}
          />
          <Lights />
          <Tv
            position={[-6.5, -2.5, 10]}
            rotation={[0.12, -0.71, 0.43]}
            {...store}
            url="/mother/error.mp4"
            opacity={0.4}
          />
          <Tv
            position={[-12, 6, -10]}
            rotation={[0, -1, 0.12]}
            {...store}
            url="/mother/error.mp4"
            opacity={0.5}
          />
          <Tv
            position={[11, 6, 1]}
            rotation={[0.12, 3.66, 6.15]}
            {...store}
            url="/mother/error.mp4"
            opacity={0.5}
          />
          <Tv
            position={[5, -4, 12]}
            rotation={[0.95, 2.83, 0.86]}
            {...store}
            url="/mother/error.mp4"
            opacity={0.5}
          />
          <Intro start={ready && clicked} set={setReady} />
        </Suspense>
      </Canvas>
      <Loader
        containerStyles={{ backgroundColor: 0x171717 }}
        barStyles={{ backgroundColor: 'silver' }}
        dataInterpolation={(p) => `Loading`}
      />
      <Overlay {...store} />
    </>
  );
};

export default NeverLeft;
