import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import Stats from 'three/examples/jsm/libs/stats.module';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';
import { GUI } from 'dat.gui';

RectAreaLightUniformsLib.init();

const Music = () => {
  const myRef = useRef();

  useEffect(() => {
    initGA();
    PageView();
  });

  let scene, camera, renderer;
  let stats;

  stats = new Stats();

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.outputEncoding = THREE.sRGBEncoding;

  camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    1,
    10000
  );
  camera.position.set(0, 8, -35);

  scene = new THREE.Scene();

  const rectLight1 = new THREE.RectAreaLight(0xff0000, 5, 4, 15);
  rectLight1.position.set(-5, 5, 5);
  scene.add(rectLight1);

  const rectLight2 = new THREE.RectAreaLight(0x00ff00, 5, 4, 15);
  rectLight2.position.set(0, 5, 5);
  scene.add(rectLight2);

  const rectLight3 = new THREE.RectAreaLight(0x0000ff, 5, 4, 15);
  rectLight3.position.set(5, 5, 5);
  scene.add(rectLight3);

  const geoFloor = new THREE.BoxGeometry(2000, 0.1, 2000);
  const matStdFloor = new THREE.MeshStandardMaterial({
    color: 0x808080,
    roughness: 0.1,
    metalness: 0,
  });
  const mshStdFloor = new THREE.Mesh(geoFloor, matStdFloor);
  scene.add(mshStdFloor);

  const geoKnot = new THREE.TorusKnotGeometry(1.5, 0.5, 200, 16);
  const matKnot = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0,
    metalness: 0,
  });
  const meshKnot = new THREE.Mesh(geoKnot, matKnot);
  meshKnot.name = 'meshKnot';
  meshKnot.position.set(0, 5, 0);
  // scene.add(meshKnot);

  new OrbitControls(camera, renderer.domElement);

  const loader = new GLTFLoader();
  let object;
  loader.load('/models/mask/scene.gltf', (gltf) => {
    object = gltf;
    gltf.scene.traverse(function (child) {
      if (child.isMesh) {
        const m = child;
        m.receiveShadow = true;
        m.castShadow = true;
      }
      if (child.isLight) {
        const l = child;
        l.castShadow = true;
        l.shadow.bias = -0.003;
        l.shadow.mapSize.width = 2048;
        l.shadow.mapSize.height = 2048;
      }
    });

    // const gui = new GUI();
    // const rotation = gui.addFolder('rotation');
    // rotation.add(gltf.scene.rotation, 'x', 0, Math.PI * 2);
    // rotation.add(gltf.scene.rotation, 'y', 0, Math.PI * 2);
    // rotation.add(gltf.scene.rotation, 'z', 0, Math.PI * 2);

    // const scale = gui.addFolder('scale');
    // scale.add(gltf.scene.scale, 'x', 0, 5);
    // scale.add(gltf.scene.scale, 'y', 0, 5);
    // scale.add(gltf.scene.scale, 'z', -10, 5);

    // const position = gui.addFolder('position');
    // position.add(gltf.scene.position, 'x', 0, 5);
    // position.add(gltf.scene.position, 'y', 0, 5);
    // position.add(gltf.scene.position, 'z', -10, 25);

    // rotation.open();

    gltf.scene.position.set(1, 2, 2);
    gltf.scene.scale.set(0.45, 0.45, 0.45);
    gltf.scene.rotation.set((5 * Math.PI * 0.96) / 3, Math.PI, 0);

    scene.add(gltf.scene);
    console.log(gltf);
  });

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  function animate() {
    requestAnimationFrame(animate);

    const time = Date.now();

    if (object) {
      object.scene.position.x = Math.sin(0.001 * time) + 1;
      object.scene.position.y = Math.cos(0.001 * time) + 4;
    }

    render();

    stats.update();
  }

  function render() {
    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>house of</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
};

export default Music;
