import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';
import { LayerMaterial, Color, Depth, Fresnel, Noise } from 'lamina/vanilla';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const Lamina = () => {
  const myRef = useRef();

  useEffect(() => {
    initGA();
    PageView();
  });

  let scene, camera, renderer;

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  scene = new THREE.Scene();

  camera = new THREE.PerspectiveCamera(
    80,
    window.innerWidth / window.innerHeight,
    0.001,
    1000
  );
  camera.position.set(2, 0, 0);

  const flowerGeometry = new THREE.TorusKnotGeometry(0.4, 0.05, 400, 32, 3, 7);
  const flowerMaterial = new LayerMaterial({
    color: new THREE.Color('#ff4eb8').convertSRGBToLinear(),
    layers: [
      new Depth({
        far: 3,
        origin: [1, 1, 1],
        colorA: new THREE.Color('#ff00e3').convertSRGBToLinear(),
        colorB: new THREE.Color('#00ffff').convertSRGBToLinear(),
        alpha: 0.5,
        mode: 'multiply',
        mapping: 'vector',
      }),
      new Depth({
        name: 'MouseDepth',
        near: 0.25,
        far: 2,
        origin: [-0.9760456268614979, 0.48266696923176067, 0],
        colorA: new THREE.Color('#ffe100').convertSRGBToLinear(),
        alpha: 0.5,
        mode: 'lighten',
        mapping: 'vector',
      }),
      new Fresnel({
        mode: 'softlight',
      }),
    ],
  });

  const flowerMesh = new THREE.Mesh(flowerGeometry, flowerMaterial);
  flowerMesh.rotateY(Math.PI / 2);
  flowerMesh.scale.setScalar(2);
  scene.add(flowerMesh);

  const pLight = new THREE.DirectionalLight();
  pLight.intensity = 2;
  pLight.shadow.mapSize.set(1024, 1024);
  scene.add(pLight);

  const controls = new OrbitControls(camera, renderer.domElement);

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  function animate() {
    requestAnimationFrame(animate);

    renderer.render(scene, camera);
  }

  animate();

  return (
    <>
      <Helmet>
        <title>I know some things</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
};

export default Lamina;
