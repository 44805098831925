import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { initGA, PageView } from "../../components/tracking";
import Model from "./Model";
import Overlay from "./Overlay";
import "./style.css";

const Analyzer = () => {
  useEffect(() => {
    initGA();
    PageView();
    // audioPlayer.current.isPlaying = false;
  }, []);

  return (
    <>
      <Helmet>
        <title>is anybody home?</title>
      </Helmet>
      <Model />
      <Overlay />
    </>
  );
};

export default Analyzer;
