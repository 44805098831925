import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { ReactComponent as Logo } from '../../imgs/logo.svg';
import Navigation from './Navigation';

const Header = () => {
  return (
    <div className="header">
      <div className="logo-container">
        <Link to="/">
          <Logo className="logo-light"/>
        </Link>
      </div>
      <Navigation />
    </div>
  );
};

export default Header;
