import React, { Suspense, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useThree } from 'react-three-fiber';
import { OrbitControls, Loader } from 'drei';
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';
import { OBJLoader } from './OBJLoader';

const SkyBox = () => {
  const {
    gl,                           // WebGL renderer
    scene,                        // Default scene
  } = useThree();
  const path = 'cube/';
	const format = '.jpeg';
	const urls = [
		path + 'px' + format, path + 'nx' + format,
		path + 'py' + format, path + 'ny' + format,
		path + 'pz' + format, path + 'nz' + format
	];

	const reflectionCube = new THREE.CubeTextureLoader().load( urls );
	const refractionCube = new THREE.CubeTextureLoader().load( urls );
	refractionCube.mapping = THREE.CubeRefractionMapping;
  scene.background = reflectionCube;

  const cubeMaterial3 = new THREE.MeshLambertMaterial( { color: 0xff6600, envMap: reflectionCube, combine: THREE.MixOperation, reflectivity: 0.3 } );
	const cubeMaterial2 = new THREE.MeshLambertMaterial( { color: 0xffee00, envMap: refractionCube, refractionRatio: 0.95 } );
	const cubeMaterial1 = new THREE.MeshLambertMaterial( { color: 0xffffff, envMap: reflectionCube } );

  const objLoader = new OBJLoader();

	objLoader.setPath( 'models/' );
	objLoader.load( 'woman.obj', function ( object ) {

		const head = object.children[ 0 ];

		head.scale.multiplyScalar( 5 );
		head.position.y = 0;
		head.material = cubeMaterial1;
    head.rotation.x = Math.PI / 2.7

		const head2 = head.clone();
		head2.position.x = - 1800;
		head2.material = cubeMaterial2;

		const head3 = head.clone();
		head3.position.x = 1800;
		head3.material = cubeMaterial3;

		scene.add( head, head2, head3 );

	} );
  gl.outputEncoding = THREE.LinearEncoding;
  gl.toneMapping = THREE.NoToneMapping;
  return null;
}

const Face = () => {
  useEffect(() => {
    initGA();
    PageView();
  })
  return (
    <>
      <Helmet>
          <title>all about you</title>
      </Helmet>
      <Canvas camera={{ position: [0,0,3500], near: 1, far: 5000, fov: 50 }} style={{ backgroundColor: 'black' }}>
        <ambientLight color="white" intensity={1}/>
        <pointLight color="white" intensity={2} />
        <Suspense fallback={null}>
          <SkyBox />
        </Suspense>
        <OrbitControls enableZoom={false} enablePan={false} minPolarAngle={Math.PI / 4} maxPolarAngle={Math.PI / 1.5}/>
      </Canvas>
      <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    </>
  )
}

export default Face;
