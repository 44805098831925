import React, { useMemo, useRef, Suspense, useEffect, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree, useLoader } from 'react-three-fiber';
import { GlitchMode } from 'postprocessing'
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { Loader, useGLTFLoader } from 'drei';
import { EffectComposer, Noise, Vignette, Glitch } from 'react-postprocessing'
import {Helmet} from 'react-helmet';
import {initGA, PageView} from '../../components/tracking';
// import Effects from "./Effects";
// import floor from "./assets/floor.jpeg"
// import babyYoda from "./assets/baby-yoda.jpeg"
// import emerald from "./assets/emerald.obj"
import circles from "./assets/circles.jpg"

import './styles.css';

// const CameraControls = () => {
//   // Get a reference to the Three.js Camera, and the canvas html element.
//   // We need these to setup the OrbitControls class.
//   // https://threejs.org/docs/#examples/en/controls/OrbitControls
//
//   const {
//     camera,
//     gl: { domElement }
//   } = useThree();
//
//   // Ref to the controls, so that we can update them on every frame using useFrame
//   const controls = useRef();
//   useFrame((state) => {
//     controls.current.update();
//     camera.position.y -= Math.sin((state.clock.getElapsedTime() ) / 2)
//   });
//   return (
//     <orbitControls
//       ref={controls}
//       args={[camera, domElement]}
//       // autoRotate={true}
//       enableZoom={false}
//     />
//   );
// };
//
// const Cube = ({ x, y, z, geometry, s, color }) => {
//   const cubeRef = useRef();
//
//   useFrame(() => {
//     cubeRef.current.rotation.x += 0.01;
//   });
//
//   return (
//     <mesh position={[x,y,z]} geometry={geometry} ref={cubeRef} scale={[s,s,s]} >
//       <meshStandardMaterial color={color} attach="material" />
//     </mesh>
//   );
// }
//
// const RandomCubes = () => {
//   const geometry = new THREE.BoxBufferGeometry(1, 1, 1);
//   function generateRandomColor() {
//     var letters = '0123456789ABCDEF';
//     var color = '#';
//     for (var i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   }
//
//   function generateColors(count) {
//     const colors = []
//     for (let i =0; i < count; i++) {
//       colors.push(generateRandomColor());
//     }
//     return colors;
//   }
//   const colorArray = generateColors(2);
//
//   const data = useMemo(() => {
//     return new Array(1300).fill().map((el, i) => ({
//       x: Math.random() * 800 - 400,
//       y: Math.random() * 400 - 200,
//       z: Math.random() * 400 - 200,
//       s: Math.random() * 20 + 1,
//       color: colorArray[Math.floor(colorArray.length * Math.random()) + 1]
//     }))
//   }, [colorArray]);
//   return data.map((props, i) => <Cube {...props} key={i} geometry={geometry}/>)
// }

// const CubeTest = () => {
//   const floorText = useLoader(THREE.TextureLoader, floor);
//   floorText.wrapS = floorText.wrapT = THREE.RepeatWrapping;
//   floorText.repeat.set( 27, 27 );
//   console.log(floorText);
//   return (
//     <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -3,0]}>
//       <planeBufferGeometry args={[102,102]} />
//       <meshStandardMaterial map={floorText} side={THREE.DoubleSide}/>
//     </mesh>
//   );
// }

function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTFLoader('/models/key/scene.gltf', true);
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
  useFrame((state, delta) => {
    mixer.update(0.011);
    // mixer._actions[0].paused = true
  })

  return (
    <group ref={group} {...props} dispose={null} position={[0,1,1]} >
      <group rotation={[-Math.PI , 0, 0]}>
        <group position={[-1.28, 1, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <group name="heart138" position={[1.07, -0.29, 0.16]} rotation={[-0.27, 0.09, -1.53]}>
            <mesh material={materials.Material} geometry={nodes.heart138_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart138_1.geometry} />
          </group>
          <group position={[1.06, -0.24, 0.14]} rotation={[-0.29, 0.08, -1.42]}>
            <mesh material={materials.Material} geometry={nodes.heart_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart_1.geometry} />
          </group>
          <group position={[1.02, -0.11, 0.11]} rotation={[-0.32, 0.03, -1.16]}>
            <mesh material={materials.Material} geometry={nodes.heart001_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart001_1.geometry} />
          </group>
          <group position={[0.94, 0.02, 0.07]} rotation={[-0.35, 0.01, -1]}>
            <mesh material={materials.Material} geometry={nodes.heart002_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart002_1.geometry} />
          </group>
          <group position={[0.85, 0.13, 0.03]} rotation={[-0.36, -0.01, -0.91]}>
            <mesh material={materials.Material} geometry={nodes.heart003_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart003_1.geometry} />
          </group>
          <group position={[0.76, 0.23, -0.01]} rotation={[-0.37, -0.01, -0.9]}>
            <mesh material={materials.Material} geometry={nodes.heart004_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart004_1.geometry} />
          </group>
          <group position={[0.67, 0.34, -0.04]} rotation={[-0.34, 0.01, -1]}>
            <mesh material={materials.Material} geometry={nodes.heart005_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart005_1.geometry} />
          </group>
          <group position={[0.6, 0.47, -0.08]} rotation={[-0.28, 0.05, -1.16]}>
            <mesh material={materials.Material} geometry={nodes.heart006_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart006_1.geometry} />
          </group>
          <group position={[0.54, 0.6, -0.1]} rotation={[-0.23, 0.07, -1.26]}>
            <mesh material={materials.Material} geometry={nodes.heart007_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart007_1.geometry} />
          </group>
          <group position={[0.5, 0.74, -0.13]} rotation={[-0.22, 0.07, -1.24]}>
            <mesh material={materials.Material} geometry={nodes.heart008_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart008_1.geometry} />
          </group>
          <group position={[0.44, 0.87, -0.15]} rotation={[-0.24, 0.06, -1.14]}>
            <mesh material={materials.Material} geometry={nodes.heart009_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart009_1.geometry} />
          </group>
          <group position={[0.26, 1.1, -0.19]} rotation={[-0.24, 0.04, -0.66]}>
            <mesh material={materials.Material} geometry={nodes.heart011_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart011_1.geometry} />
          </group>
          <group position={[0.13, 1.16, -0.2]} rotation={[-0.21, 0.09, -0.24]}>
            <mesh material={materials.Material} geometry={nodes.heart012_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart012_1.geometry} />
          </group>
          <group position={[-0.01, 1.17, -0.18]} rotation={[-0.2, 0.18, 0.2]}>
            <mesh material={materials.Material} geometry={nodes.heart013_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart013_1.geometry} />
          </group>
          <group position={[-0.14, 1.12, -0.13]} rotation={[-0.23, 0.26, 0.55]}>
            <mesh material={materials.Material} geometry={nodes.heart014_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart014_1.geometry} />
          </group>
          <group position={[-0.25, 1.03, -0.07]} rotation={[-0.26, 0.31, 0.79]}>
            <mesh material={materials.Material} geometry={nodes.heart015_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart015_1.geometry} />
          </group>
          <group position={[-0.33, 0.93, -0.01]} rotation={[-0.29, 0.34, 0.94]}>
            <mesh material={materials.Material} geometry={nodes.heart016_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart016_1.geometry} />
          </group>
          <group position={[-0.41, 0.82, 0.05]} rotation={[-0.29, 0.34, 1.02]}>
            <mesh material={materials.Material} geometry={nodes.heart017_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart017_1.geometry} />
          </group>
          <group position={[-0.47, 0.7, 0.11]} rotation={[-0.25, 0.33, 1.03]}>
            <mesh material={materials.Material} geometry={nodes.heart018_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart018_1.geometry} />
          </group>
          <group position={[-0.55, 0.59, 0.16]} rotation={[-0.18, 0.28, 0.93]}>
            <mesh material={materials.Material} geometry={nodes.heart019_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart019_1.geometry} />
          </group>
          <group position={[-0.64, 0.48, 0.21]} rotation={[-0.09, 0.2, 0.75]}>
            <mesh material={materials.Material} geometry={nodes.heart020_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart020_1.geometry} />
          </group>
          <group position={[-0.75, 0.39, 0.23]} rotation={[-0.02, 0.12, 0.63]}>
            <mesh material={materials.Material} geometry={nodes.heart021_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart021_1.geometry} />
          </group>
          <group position={[-0.87, 0.3, 0.24]} rotation={[0.03, 0.06, 0.63]}>
            <mesh material={materials.Material} geometry={nodes.heart022_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart022_1.geometry} />
          </group>
          <group position={[-0.98, 0.2, 0.25]} rotation={[0.06, 0.03, 0.74]}>
            <mesh material={materials.Material} geometry={nodes.heart023_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart023_1.geometry} />
          </group>
          <group position={[-1.08, 0.09, 0.24]} rotation={[0.1, 0, 0.92]}>
            <mesh material={materials.Material} geometry={nodes.heart024_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart024_1.geometry} />
          </group>
          <group position={[-1.15, -0.03, 0.23]} rotation={[0.15, -0.02, 1.2]}>
            <mesh material={materials.Material} geometry={nodes.heart025_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart025_1.geometry} />
          </group>
          <group position={[-1.17, -0.18, 0.21]} rotation={[0.21, -0.02, 1.58]}>
            <mesh material={materials.Material} geometry={nodes.heart026_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart026_1.geometry} />
          </group>
          <group position={[-1.14, -0.31, 0.18]} rotation={[0.28, 0.01, 1.99]}>
            <mesh material={materials.Material} geometry={nodes.heart027_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart027_1.geometry} />
          </group>
          <group position={[-1.05, -0.43, 0.14]} rotation={[0.33, 0.06, 2.33]}>
            <mesh material={materials.Material} geometry={nodes.heart028_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart028_1.geometry} />
          </group>
          <group position={[-0.94, -0.51, 0.11]} rotation={[0.36, 0.11, 2.58]}>
            <mesh material={materials.Material} geometry={nodes.heart029_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart029_1.geometry} />
          </group>
          <group position={[-0.8, -0.56, 0.07]} rotation={[0.38, 0.16, 2.74]}>
            <mesh material={materials.Material} geometry={nodes.heart030_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart030_1.geometry} />
          </group>
          <group position={[-0.67, -0.6, 0.03]} rotation={[0.39, 0.19, 2.82]}>
            <mesh material={materials.Material} geometry={nodes.heart031_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart031_1.geometry} />
          </group>
          <group position={[-0.53, -0.63, -0.01]} rotation={[0.38, 0.19, 2.82]}>
            <mesh material={materials.Material} geometry={nodes.heart032_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart032_1.geometry} />
          </group>
          <group position={[-0.39, -0.67, -0.05]} rotation={[0.37, 0.15, 2.73]}>
            <mesh material={materials.Material} geometry={nodes.heart033_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart033_1.geometry} />
          </group>
          <group position={[-0.26, -0.73, -0.08]} rotation={[0.32, 0.06, 2.58]}>
            <mesh material={materials.Material} geometry={nodes.heart034_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart034_1.geometry} />
          </group>
          <group position={[-0.14, -0.8, -0.1]} rotation={[0.27, -0.03, 2.5]}>
            <mesh material={materials.Material} geometry={nodes.heart035_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart035_1.geometry} />
          </group>
          <group position={[-0.02, -0.89, -0.11]} rotation={[0.23, -0.07, 2.54]}>
            <mesh material={materials.Material} geometry={nodes.heart036_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart036_1.geometry} />
          </group>
          <group position={[0.11, -0.96, -0.11]} rotation={[0.21, -0.1, 2.66]}>
            <mesh material={materials.Material} geometry={nodes.heart037_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart037_1.geometry} />
          </group>
          <group position={[0.24, -1.02, -0.11]} rotation={[0.2, -0.11, 2.87]}>
            <mesh material={materials.Material} geometry={nodes.heart038_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart038_1.geometry} />
          </group>
          <group position={[0.39, -1.04, -0.09]} rotation={[0.19, -0.11, -3.12]}>
            <mesh material={materials.Material} geometry={nodes.heart039_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart039_1.geometry} />
          </group>
          <group position={[0.53, -1.01, -0.07]} rotation={[0.18, -0.11, -2.72]}>
            <mesh material={materials.Material} geometry={nodes.heart040_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart040_1.geometry} />
          </group>
          <group position={[0.64, -0.92, -0.04]} rotation={[0.18, -0.12, -2.3]}>
            <mesh material={materials.Material} geometry={nodes.heart041_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart041_1.geometry} />
          </group>
          <group position={[0.72, -0.8, 0]} rotation={[0.19, -0.14, -1.97]}>
            <mesh material={materials.Material} geometry={nodes.heart042_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart042_1.geometry} />
          </group>
          <group position={[0.75, -0.66, 0.04]} rotation={[0.22, -0.15, -1.73]}>
            <mesh material={materials.Material} geometry={nodes.heart043_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart043_1.geometry} />
          </group>
          <group position={[0.76, -0.52, 0.07]} rotation={[0.24, -0.16, -1.57]}>
            <mesh material={materials.Material} geometry={nodes.heart044_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart044_1.geometry} />
          </group>
          <group position={[0.75, -0.38, 0.11]} rotation={[0.26, -0.16, -1.5]}>
            <mesh material={materials.Material} geometry={nodes.heart045_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart045_1.geometry} />
          </group>
          <group position={[0.74, -0.24, 0.15]} rotation={[0.25, -0.16, -1.52]}>
            <mesh material={materials.Material} geometry={nodes.heart046_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart046_1.geometry} />
          </group>
          <group position={[0.73, -0.1, 0.19]} rotation={[0.21, -0.16, -1.65]}>
            <mesh material={materials.Material} geometry={nodes.heart047_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart047_1.geometry} />
          </group>
          <group position={[0.75, 0.05, 0.22]} rotation={[0.12, -0.14, -1.82]}>
            <mesh material={materials.Material} geometry={nodes.heart048_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart048_1.geometry} />
          </group>
          <group position={[0.79, 0.19, 0.24]} rotation={[0.04, -0.12, -1.9]}>
            <mesh material={materials.Material} geometry={nodes.heart049_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart049_1.geometry} />
          </group>
          <group position={[0.83, 0.33, 0.25]} rotation={[-0.02, -0.11, -1.85]}>
            <mesh material={materials.Material} geometry={nodes.heart050_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart050_1.geometry} />
          </group>
          <group position={[0.86, 0.47, 0.25]} rotation={[-0.06, -0.1, -1.72]}>
            <mesh material={materials.Material} geometry={nodes.heart051_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart051_1.geometry} />
          </group>
          <group position={[0.87, 0.62, 0.24]} rotation={[-0.1, -0.11, -1.51]}>
            <mesh material={materials.Material} geometry={nodes.heart052_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart052_1.geometry} />
          </group>
          <group position={[0.83, 0.76, 0.22]} rotation={[-0.14, -0.13, -1.19]}>
            <mesh material={materials.Material} geometry={nodes.heart053_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart053_1.geometry} />
          </group>
          <group position={[0.75, 0.88, 0.19]} rotation={[-0.2, -0.19, -0.8]}>
            <mesh material={materials.Material} geometry={nodes.heart054_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart054_1.geometry} />
          </group>
          <group position={[0.63, 0.95, 0.15]} rotation={[-0.27, -0.3, -0.43]}>
            <mesh material={materials.Material} geometry={nodes.heart055_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart055_1.geometry} />
          </group>
          <group position={[0.5, 0.97, 0.09]} rotation={[-0.3, -0.43, -0.17]}>
            <mesh material={materials.Material} geometry={nodes.heart056_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart056_1.geometry} />
          </group>
          <group position={[0.37, 0.96, 0.03]} rotation={[-0.3, -0.53, 0]}>
            <mesh material={materials.Material} geometry={nodes.heart057_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart057_1.geometry} />
          </group>
          <group position={[0.24, 0.93, -0.04]} rotation={[-0.29, -0.59, 0.12]}>
            <mesh material={materials.Material} geometry={nodes.heart058_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart058_1.geometry} />
          </group>
          <group position={[0.12, 0.88, -0.11]} rotation={[-0.28, -0.6, 0.19]}>
            <mesh material={materials.Material} geometry={nodes.heart059_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart059_1.geometry} />
          </group>
          <group position={[0, 0.83, -0.17]} rotation={[-0.3, -0.53, 0.22]}>
            <mesh material={materials.Material} geometry={nodes.heart060_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart060_1.geometry} />
          </group>
          <group position={[-0.13, 0.78, -0.21]} rotation={[-0.35, -0.33, 0.18]}>
            <mesh material={materials.Material} geometry={nodes.heart061_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart061_1.geometry} />
          </group>
          <group position={[-0.27, 0.75, -0.23]} rotation={[-0.4, -0.04, 0.1]}>
            <mesh material={materials.Material} geometry={nodes.heart062_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart062_1.geometry} />
          </group>
          <group position={[-0.42, 0.74, -0.21]} rotation={[-0.41, 0.13, 0.06]}>
            <mesh material={materials.Material} geometry={nodes.heart063_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart063_1.geometry} />
          </group>
          <group position={[-0.56, 0.74, -0.18]} rotation={[-0.41, 0.16, 0.1]}>
            <mesh material={materials.Material} geometry={nodes.heart064_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart064_1.geometry} />
          </group>
          <group position={[-0.71, 0.72, -0.15]} rotation={[-0.4, 0.1, 0.19]}>
            <mesh material={materials.Material} geometry={nodes.heart065_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart065_1.geometry} />
          </group>
          <group position={[-0.85, 0.69, -0.12]} rotation={[-0.37, 0.03, 0.36]}>
            <mesh material={materials.Material} geometry={nodes.heart066_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart066_1.geometry} />
          </group>
          <group position={[-0.98, 0.62, -0.1]} rotation={[-0.32, -0.05, 0.63]}>
            <mesh material={materials.Material} geometry={nodes.heart067_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart067_1.geometry} />
          </group>
          <group position={[-1.07, 0.52, -0.07]} rotation={[-0.26, -0.09, 1.02]}>
            <mesh material={materials.Material} geometry={nodes.heart068_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart068_1.geometry} />
          </group>
          <group position={[-1.12, 0.38, -0.04]} rotation={[-0.23, -0.09, 1.43]}>
            <mesh material={materials.Material} geometry={nodes.heart069_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart069_1.geometry} />
          </group>
          <group position={[-1.11, 0.24, 0]} rotation={[-0.22, -0.08, 1.77]}>
            <mesh material={materials.Material} geometry={nodes.heart070_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart070_1.geometry} />
          </group>
          <group position={[-1.06, 0.11, 0.03]} rotation={[-0.22, -0.08, 2.02]}>
            <mesh material={materials.Material} geometry={nodes.heart071_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart071_1.geometry} />
          </group>
          <group position={[-0.98, -0.01, 0.07]} rotation={[-0.24, -0.08, 2.17]}>
            <mesh material={materials.Material} geometry={nodes.heart072_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart072_1.geometry} />
          </group>
          <group position={[-0.89, -0.12, 0.11]} rotation={[-0.25, -0.08, 2.25]}>
            <mesh material={materials.Material} geometry={nodes.heart073_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart073_1.geometry} />
          </group>
          <group position={[0.37, 1, -0.18]} rotation={[-0.25, 0.04, -0.95]}>
            <mesh material={materials.Material} geometry={nodes.heart010_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart010_1.geometry} />
          </group>
          <group position={[-0.71, -0.34, 0.19]} rotation={[-0.22, -0.06, 2.12]}>
            <mesh material={materials.Material} geometry={nodes.heart075_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart075_1.geometry} />
          </group>
          <group position={[-0.64, -0.46, 0.22]} rotation={[-0.16, -0.03, 1.96]}>
            <mesh material={materials.Material} geometry={nodes.heart076_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart076_1.geometry} />
          </group>
          <group position={[-0.59, -0.6, 0.24]} rotation={[-0.08, 0, 1.88]}>
            <mesh material={materials.Material} geometry={nodes.heart077_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart077_1.geometry} />
          </group>
          <group position={[-0.54, -0.74, 0.25]} rotation={[-0.02, 0.02, 1.91]}>
            <mesh material={materials.Material} geometry={nodes.heart078_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart078_1.geometry} />
          </group>
          <group position={[-0.48, -0.87, 0.25]} rotation={[0.02, 0.05, 2.04]}>
            <mesh material={materials.Material} geometry={nodes.heart079_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart079_1.geometry} />
          </group>
          <group position={[-0.4, -1, 0.24]} rotation={[0.06, 0.08, 2.25]}>
            <mesh material={materials.Material} geometry={nodes.heart080_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart080_1.geometry} />
          </group>
          <group position={[-0.29, -1.09, 0.23]} rotation={[0.1, 0.13, 2.55]}>
            <mesh material={materials.Material} geometry={nodes.heart081_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart081_1.geometry} />
          </group>
          <group position={[-0.16, -1.14, 0.2]} rotation={[0.12, 0.21, 2.95]}>
            <mesh material={materials.Material} geometry={nodes.heart082_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart082_1.geometry} />
          </group>
          <group position={[-0.02, -1.13, 0.17]} rotation={[0.1, 0.3, -2.93]}>
            <mesh material={materials.Material} geometry={nodes.heart083_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart083_1.geometry} />
          </group>
          <group position={[0.11, -1.07, 0.13]} rotation={[0.06, 0.37, -2.61]}>
            <mesh material={materials.Material} geometry={nodes.heart084_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart084_1.geometry} />
          </group>
          <group position={[0.22, -0.98, 0.09]} rotation={[0.01, 0.41, -2.37]}>
            <mesh material={materials.Material} geometry={nodes.heart085_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart085_1.geometry} />
          </group>
          <group position={[0.3, -0.87, 0.06]} rotation={[-0.03, 0.44, -2.22]}>
            <mesh material={materials.Material} geometry={nodes.heart086_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart086_1.geometry} />
          </group>
          <group position={[0.38, -0.75, 0.02]} rotation={[-0.06, 0.45, -2.15]}>
            <mesh material={materials.Material} geometry={nodes.heart087_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart087_1.geometry} />
          </group>
          <group position={[0.45, -0.62, -0.02]} rotation={[-0.05, 0.45, -2.18]}>
            <mesh material={materials.Material} geometry={nodes.heart088_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart088_1.geometry} />
          </group>
          <group position={[0.53, -0.51, -0.06]} rotation={[0.02, 0.4, -2.33]}>
            <mesh material={materials.Material} geometry={nodes.heart089_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart089_1.geometry} />
          </group>
          <group position={[0.64, -0.41, -0.09]} rotation={[0.1, 0.32, -2.5]}>
            <mesh material={materials.Material} geometry={nodes.heart090_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart090_1.geometry} />
          </group>
          <group position={[0.75, -0.32, -0.1]} rotation={[0.15, 0.25, -2.55]}>
            <mesh material={materials.Material} geometry={nodes.heart091_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart091_1.geometry} />
          </group>
          <group position={[0.87, -0.23, -0.11]} rotation={[0.18, 0.21, -2.49]}>
            <mesh material={materials.Material} geometry={nodes.heart092_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart092_1.geometry} />
          </group>
          <group position={[0.97, -0.13, -0.11]} rotation={[0.19, 0.19, -2.34]}>
            <mesh material={materials.Material} geometry={nodes.heart093_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart093_1.geometry} />
          </group>
          <group position={[1.06, -0.01, -0.1]} rotation={[0.18, 0.19, -2.1]}>
            <mesh material={materials.Material} geometry={nodes.heart094_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart094_1.geometry} />
          </group>
          <group position={[1.11, 0.13, -0.09]} rotation={[0.17, 0.18, -1.77]}>
            <mesh material={materials.Material} geometry={nodes.heart095_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart095_1.geometry} />
          </group>
          <group position={[1.11, 0.27, -0.06]} rotation={[0.15, 0.17, -1.35]}>
            <mesh material={materials.Material} geometry={nodes.heart096_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart096_1.geometry} />
          </group>
          <group position={[1.04, 0.4, -0.02]} rotation={[0.16, 0.16, -0.95]}>
            <mesh material={materials.Material} geometry={nodes.heart097_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart097_1.geometry} />
          </group>
          <group position={[0.94, 0.5, 0.01]} rotation={[0.17, 0.16, -0.65]}>
            <mesh material={materials.Material} geometry={nodes.heart098_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart098_1.geometry} />
          </group>
          <group position={[0.82, 0.56, 0.05]} rotation={[0.18, 0.18, -0.44]}>
            <mesh material={materials.Material} geometry={nodes.heart099_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart099_1.geometry} />
          </group>
          <group position={[0.68, 0.61, 0.09]} rotation={[0.19, 0.19, -0.32]}>
            <mesh material={materials.Material} geometry={nodes.heart100_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart100_1.geometry} />
          </group>
          <group position={[0.54, 0.64, 0.12]} rotation={[0.2, 0.2, -0.27]}>
            <mesh material={materials.Material} geometry={nodes.heart101_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart101_1.geometry} />
          </group>
          <group position={[0.4, 0.67, 0.16]} rotation={[0.2, 0.19, -0.32]}>
            <mesh material={materials.Material} geometry={nodes.heart102_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart102_1.geometry} />
          </group>
          <group position={[0.27, 0.72, 0.2]} rotation={[0.17, 0.14, -0.47]}>
            <mesh material={materials.Material} geometry={nodes.heart103_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart103_1.geometry} />
          </group>
          <group position={[0.14, 0.79, 0.22]} rotation={[0.13, 0.06, -0.61]}>
            <mesh material={materials.Material} geometry={nodes.heart104_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart104_1.geometry} />
          </group>
          <group position={[0.02, 0.87, 0.24]} rotation={[0.09, 0, -0.64]}>
            <mesh material={materials.Material} geometry={nodes.heart105_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart105_1.geometry} />
          </group>
          <group position={[-0.1, 0.95, 0.25]} rotation={[0.06, -0.04, -0.56]}>
            <mesh material={materials.Material} geometry={nodes.heart106_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart106_1.geometry} />
          </group>
          <group position={[-0.8, -0.23, 0.15]} rotation={[-0.25, -0.08, 2.24]}>
            <mesh material={materials.Material} geometry={nodes.heart074_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart074_1.geometry} />
          </group>
          <group position={[-0.23, 1.02, 0.25]} rotation={[0.05, -0.08, -0.4]}>
            <mesh material={materials.Material} geometry={nodes.heart107_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart107_1.geometry} />
          </group>
          <group position={[-0.37, 1.06, 0.24]} rotation={[0.04, -0.13, -0.16]}>
            <mesh material={materials.Material} geometry={nodes.heart108_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart108_1.geometry} />
          </group>
          <group position={[-0.52, 1.06, 0.22]} rotation={[0.05, -0.18, 0.2]}>
            <mesh material={materials.Material} geometry={nodes.heart109_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart109_1.geometry} />
          </group>
          <group position={[-0.65, 1, 0.19]} rotation={[0.09, -0.23, 0.62]}>
            <mesh material={materials.Material} geometry={nodes.heart110_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart110_1.geometry} />
          </group>
          <group position={[-0.74, 0.89, 0.15]} rotation={[0.15, -0.27, 1.01]}>
            <mesh material={materials.Material} geometry={nodes.heart111_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart111_1.geometry} />
          </group>
          <group position={[-0.8, 0.76, 0.12]} rotation={[0.21, -0.29, 1.31]}>
            <mesh material={materials.Material} geometry={nodes.heart112_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart112_1.geometry} />
          </group>
          <group position={[-0.81, 0.62, 0.08]} rotation={[0.27, -0.29, 1.51]}>
            <mesh material={materials.Material} geometry={nodes.heart113_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart113_1.geometry} />
          </group>
          <group position={[-0.81, 0.48, 0.04]} rotation={[0.3, -0.28, 1.63]}>
            <mesh material={materials.Material} geometry={nodes.heart114_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart114_1.geometry} />
          </group>
          <group position={[-0.8, 0.33, 0]} rotation={[0.32, -0.28, 1.66]}>
            <mesh material={materials.Material} geometry={nodes.heart115_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart115_1.geometry} />
          </group>
          <group position={[-0.79, 0.19, -0.03]} rotation={[0.29, -0.28, 1.58]}>
            <mesh material={materials.Material} geometry={nodes.heart116_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart116_1.geometry} />
          </group>
          <group position={[-0.8, 0.05, -0.07]} rotation={[0.2, -0.28, 1.41]}>
            <mesh material={materials.Material} geometry={nodes.heart117_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart117_1.geometry} />
          </group>
          <group position={[-0.83, -0.09, -0.09]} rotation={[0.09, -0.26, 1.27]}>
            <mesh material={materials.Material} geometry={nodes.heart118_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart118_1.geometry} />
          </group>
          <group position={[-0.87, -0.23, -0.11]} rotation={[0.02, -0.23, 1.26]}>
            <mesh material={materials.Material} geometry={nodes.heart119_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart119_1.geometry} />
          </group>
          <group position={[-0.91, -0.37, -0.11]} rotation={[-0.02, -0.22, 1.35]}>
            <mesh material={materials.Material} geometry={nodes.heart120_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart120_1.geometry} />
          </group>
          <group position={[-0.92, -0.52, -0.11]} rotation={[-0.04, -0.21, 1.52]}>
            <mesh material={materials.Material} geometry={nodes.heart121_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart121_1.geometry} />
          </group>
          <group position={[-0.91, -0.66, -0.1]} rotation={[-0.04, -0.21, 1.79]}>
            <mesh material={materials.Material} geometry={nodes.heart122_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart122_1.geometry} />
          </group>
          <group position={[-0.85, -0.8, -0.08]} rotation={[-0.05, -0.2, 2.16]}>
            <mesh material={materials.Material} geometry={nodes.heart123_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart123_1.geometry} />
          </group>
          <group position={[-0.75, -0.89, -0.05]} rotation={[-0.07, -0.2, 2.58]}>
            <mesh material={materials.Material} geometry={nodes.heart124_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart124_1.geometry} />
          </group>
          <group position={[-0.62, -0.94, -0.01]} rotation={[-0.08, -0.22, 2.94]}>
            <mesh material={materials.Material} geometry={nodes.heart125_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart125_1.geometry} />
          </group>
          <group position={[-0.47, -0.94, 0.03]} rotation={[-0.09, -0.24, -3.08]}>
            <mesh material={materials.Material} geometry={nodes.heart126_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart126_1.geometry} />
          </group>
          <group position={[-0.34, -0.91, 0.06]} rotation={[-0.09, -0.27, -2.9]}>
            <mesh material={materials.Material} geometry={nodes.heart127_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart127_1.geometry} />
          </group>
          <group position={[-0.2, -0.87, 0.1]} rotation={[-0.09, -0.29, -2.81]}>
            <mesh material={materials.Material} geometry={nodes.heart128_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart128_1.geometry} />
          </group>
          <group position={[-0.07, -0.81, 0.14]} rotation={[-0.09, -0.3, -2.79]}>
            <mesh material={materials.Material} geometry={nodes.heart129_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart129_1.geometry} />
          </group>
          <group position={[0.06, -0.76, 0.18]} rotation={[-0.1, -0.27, -2.88]}>
            <mesh material={materials.Material} geometry={nodes.heart130_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart130_1.geometry} />
          </group>
          <group position={[0.2, -0.72, 0.21]} rotation={[-0.11, -0.19, -3.05]}>
            <mesh material={materials.Material} geometry={nodes.heart131_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart131_1.geometry} />
          </group>
          <group position={[0.34, -0.71, 0.23]} rotation={[-0.12, -0.1, 3.13]}>
            <mesh material={materials.Material} geometry={nodes.heart132_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart132_1.geometry} />
          </group>
          <group position={[0.49, -0.71, 0.25]} rotation={[-0.12, -0.03, -3.14]}>
            <mesh material={materials.Material} geometry={nodes.heart133_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart133_1.geometry} />
          </group>
          <group position={[0.64, -0.7, 0.25]} rotation={[-0.13, 0.01, -3.02]}>
            <mesh material={materials.Material} geometry={nodes.heart134_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart134_1.geometry} />
          </group>
          <group position={[0.78, -0.67, 0.24]} rotation={[-0.14, 0.04, -2.83]}>
            <mesh material={materials.Material} geometry={nodes.heart135_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart135_1.geometry} />
          </group>
          <group position={[0.91, -0.6, 0.23]} rotation={[-0.16, 0.07, -2.54]}>
            <mesh material={materials.Material} geometry={nodes.heart136_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart136_1.geometry} />
          </group>
          <group position={[1.01, -0.5, 0.21]} rotation={[-0.2, 0.09, -2.15]}>
            <mesh material={materials.Material} geometry={nodes.heart137_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.heart137_1.geometry} />
          </group>
          <group position={[0, 1.16, 0]}>
            <mesh material={materials.Material} geometry={nodes.key_0.geometry} />
            <mesh material={materials['Material.001']} geometry={nodes.key_1.geometry} />
            <mesh material={materials['Material.002']} geometry={nodes.key_2.geometry} />
          </group>
        </group>
      </group>
    </group>
  )
}

const Lights = ({ position }) => {

  const light1Ref = useRef();
  const light2Ref = useRef();
  const light3Ref = useRef();
  const light4Ref = useRef();

  useFrame(() => {
    const time = Date.now() * 0.001;
    light1Ref.current.position.x = Math.cos( time * 0.7 ) * 1;
    light1Ref.current.position.y = Math.sin( time * 0.5 ) * 1.5;
    // light1Ref.current.position.z = Math.cos( time * 0.3 ) * 0.6;

    light2Ref.current.position.x = Math.sin( time * 0.3 ) * 2;
    light2Ref.current.position.y = Math.cos( time * 0.5 ) * 0.65;
    // light2Ref.current.position.z = Math.cos( time * 0.7 ) * 0.7;

    light3Ref.current.position.x = Math.sin( time * 0.3 ) * 1.5;
    light3Ref.current.position.y = Math.cos( time * 0.5 ) * 1.25;
    // light3Ref.current.position.z = Math.cos( time * 0.7 ) * 0.5;

    light4Ref.current.position.x = Math.sin( time * 0.3 ) * 1;
    light4Ref.current.position.y = Math.cos( time * 0.5 ) * 0.25;
    // light4Ref.current.position.z = Math.cos( time * 0.7 );
  });

  return (
    <>
    <group position={position}>
      <pointLight args={[0xffffff, 3, 0]} ref={light1Ref} position={[-2, 2, 0]}>
        <mesh>
          <sphereGeometry args={[0.1, 16, 8]} />
          <meshBasicMaterial color={0xffffff} />
        </mesh>
      </pointLight>
      <pointLight args={[0xffffff, 3, 0 ]} ref={light2Ref} position={[2, 6, 0]}>
        <mesh>
          <sphereGeometry args={[0.1, 16, 8]} />
          <meshBasicMaterial color={0xffffff} />
        </mesh>
      </pointLight>
      <pointLight args={[0xffffff, 3, 0 ]} ref={light3Ref} position={[0, 2, 1]}>
        <mesh>
          <sphereGeometry args={[0.1, 16, 8]} />
          <meshBasicMaterial color={0xffffff} />
        </mesh>
      </pointLight>
      <pointLight args={[0xffffff, 3, 0 ]} ref={light4Ref} position={[0, 4, 0]}>
        <mesh>
          <sphereGeometry args={[0.1, 16, 8]} />
          <meshBasicMaterial color={0xffffff} />
        </mesh>
      </pointLight>
    </group>
    </>
  );
}

const rpi = () => Math.random() * Math.PI
const Thing = React.forwardRef(({ amount = 100, opacity, ...props }, ref) => {
  const mesh = useRef()
  const dummy = new THREE.Object3D()
  const coords = useMemo(() => new Array(amount).fill().map(() => [rpi(), rpi(), rpi()]), [amount])
  useFrame(state => {
    const t = state.clock.getElapsedTime()
    coords.forEach(([x, y, z], i) => {
      dummy.updateMatrix(void dummy.rotation.set(x + t, y + t, z + t))
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })
  return (
    <group ref={ref} {...props}>
      <instancedMesh ref={mesh} args={[null, null, amount]} renderOrder={2}>
        <boxBufferGeometry attach="geometry" />
        <meshNormalMaterial attach="material" transparent opacity={opacity} />
      </instancedMesh>
    </group>
  )
})

function Yoda() {
  const plane = useRef()
  const eyes = useRef()
  const thing = useRef()
  const { viewport, aspect } = useThree()
  const texture = useLoader(THREE.TextureLoader, circles)
  useMemo(() => (texture.minFilter = THREE.LinearFilter), [])
  const adaptedHeight = 3800 * (aspect > 5000 / 3800 ? viewport.width / 5000 : viewport.height / 3800)
  const adaptedWidth = 5000 * (aspect > 5000 / 3800 ? viewport.width / 5000 : viewport.height / 3800)
  const scale = adaptedWidth / 25
  useFrame(state => {
    // plane.current.rotation.x = Math.sin(state.clock.getElapsedTime()) / 15
    thing.current.position.y = Math.sin(state.clock.getElapsedTime()) * 2
    eyes.current.position.y = Math.sin(state.clock.getElapsedTime()) / 2
  })
  return (
    <>
      <mesh ref={plane} scale={[adaptedWidth + 1, adaptedHeight + 0.5, 1]}>
        <planeBufferGeometry attach="geometry" args={[1, 1]} />
        <meshBasicMaterial attach="material" map={texture} />
      </mesh>
      <group ref={eyes}>
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[-adaptedWidth / 9.5, adaptedHeight / 4.4, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[-adaptedWidth / 3.7, adaptedHeight / 3.7, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[-adaptedWidth / 3.7, adaptedHeight / 20, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[-adaptedWidth / 3.7, - adaptedHeight / 5.8, 1]} amount={100} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[adaptedWidth / 38.5, adaptedHeight / 4.4, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[-adaptedWidth / 9.5, - adaptedHeight / 6.8, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[adaptedWidth / 38.5, - adaptedHeight / 6.8, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[adaptedWidth / 38.5, - adaptedHeight / 2.9, 1]} amount={10} opacity={0.1} />
        <Thing scale={[scale / 2, scale / 2, scale / 2]} position={[adaptedWidth / 5.7, adaptedHeight / 3.9, 1]} amount={10} opacity={0.1} />
        <group position={[-4,1.7,4]}>
          <Model scale={[0.44,0.44,0.44]} />
          <Lights />
        </group>
      </group>
      <Thing ref={thing} scale={[scale * 4, scale * 4, scale * 4]} position={[adaptedWidth / 5, adaptedHeight / 10, 0]} amount={50} opacity={1} />
    </>
  )
}

function Effects({ factor }) {


  return (
    <>
    <group scale={[0.5,0.5,0.5]}>
      <EffectComposer>
        {/* <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} /> */}
        {/* <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} /> */}
        <Noise opacity={0.12} />
        <Glitch
          delay={[1.5, 3.5]} // min and max glitch delay
          duration={[0.6, 1.0]} // min and max glitch duration
          strength={[0.1, 0.3]} // min and max glitch strength
          mode={GlitchMode.SPORADIC} // glitch mode
          active={false} // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
          ratio={0.1} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
        />
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
        {/* <Pixelation
          granularity={1} // pixel granularity
        /> */}
      </EffectComposer>
    </group>
    </>
  )
}

const Playground = () => {
  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <>
      <Helmet>
          <title>you know</title>
      </Helmet>
      <Canvas style={{ background: '#171717' }} camera={{ fov: 50, position: [0, 0, 30] }}>
        <ambientLight intensity={0.5}/>
        <Effects />
        <pointLight intensity={0.7} position={[0, 0, 0]} />
        <Suspense fallback={null} >
          <Yoda />
          {/* <RandomCubes /> */}
        </Suspense>
      </Canvas>
      <Loader containerStyles={{backgroundColor: 0x171717}} barStyles={{ backgroundColor: 'silver' }} dataInterpolation={(p) => `Loading`}/>
    </>
  );
}

export default Playground;
