import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { initGA, PageView } from '../../components/tracking';

export default function Nine() {
  useEffect(() => {
    initGA();
    PageView();
  });

  const myRef = useRef();

  let camera, scene, renderer;
  const AMOUNT = 4;

  const ASPECT_RATIO = window.innerWidth / window.innerHeight;

  const WIDTH = (window.innerWidth / AMOUNT) * window.devicePixelRatio;
  const HEIGHT = (window.innerHeight / AMOUNT) * window.devicePixelRatio;

  const cameras = [];

  for (let y = 0; y < AMOUNT; y++) {
    for (let x = 0; x < AMOUNT; x++) {
      const subcamera = new THREE.PerspectiveCamera(40, ASPECT_RATIO, 0.1, 10);
      subcamera.viewport = new THREE.Vector4(
        Math.floor(x * WIDTH),
        Math.floor(y * HEIGHT),
        Math.ceil(WIDTH),
        Math.ceil(HEIGHT)
      );
      subcamera.position.x = x / AMOUNT - 0.5;
      subcamera.position.y = 0.5 - y / AMOUNT;
      subcamera.position.z = 1.5;
      subcamera.position.multiplyScalar(2);
      subcamera.lookAt(0, 0, 0);
      subcamera.updateMatrixWorld();
      cameras.push(subcamera);
    }
  }

  camera = new THREE.ArrayCamera(cameras);
  camera.position.z = 2;

  scene = new THREE.Scene();

  const ambientLight1 = new THREE.AmbientLight(0x222244);
  ambientLight1.intensity = 2;

  scene.add(ambientLight1);

  const light = new THREE.DirectionalLight();
  light.intensity = 1;
  light.position.set(1.5, 0, 3);
  light.castShadow = true;
  light.shadow.camera.zoom = 4; // tighter shadow map
  scene.add(light);

  const geometryBackground = new THREE.PlaneGeometry(100, 100);
  const materialBackground = new THREE.MeshPhongMaterial({ color: 0x000066 });

  const background = new THREE.Mesh(geometryBackground, materialBackground);
  background.receiveShadow = true;
  background.position.set(0, 0, -1);
  scene.add(background);

  // const geometryCylinder = new THREE.BoxGeometry(1, 1, 1);
  // const materialCylinder = new THREE.MeshPhongMaterial({ color: 0xff0000 });

  // mesh = new THREE.Mesh(geometryCylinder, materialCylinder);
  // mesh.castShadow = true;
  // mesh.receiveShadow = true;
  // scene.add(mesh);
  var head;
  const loader = new GLTFLoader();
  loader.setPath('balloon/');
  loader.load('scene.gltf', function (gltf) {
    head = gltf.scene.children[0];
    head.position.y = 0.1;
    head.scale.multiplyScalar(0.08);
    head.receiveShadow = true;
    head.castShadow = true;
    scene.add(gltf.scene);
  });

  renderer = new THREE.WebGLRenderer();
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.shadowMap.enabled = true;

  useEffect(() => {
    myRef.current.appendChild(renderer.domElement);
  });

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  function onWindowResize() {
    const ASPECT_RATIO = window.innerWidth / window.innerHeight;
    const WIDTH = (window.innerWidth / AMOUNT) * window.devicePixelRatio;
    const HEIGHT = (window.innerHeight / AMOUNT) * window.devicePixelRatio;

    camera.aspect = ASPECT_RATIO;
    camera.updateProjectionMatrix();

    for (let y = 0; y < AMOUNT; y++) {
      for (let x = 0; x < AMOUNT; x++) {
        const subcamera = camera.cameras[AMOUNT * y + x];

        subcamera.viewport.set(
          Math.floor(x * WIDTH),
          Math.floor(y * HEIGHT),
          Math.ceil(WIDTH),
          Math.ceil(HEIGHT)
        );

        subcamera.aspect = ASPECT_RATIO;
        subcamera.updateProjectionMatrix();
      }
    }

    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  function animate() {
    // head.rotation.x += 0.005;
    // mesh.rotation.z += 0.01;

    if (head) {
      head.rotation.z += 0.005;
      head.rotation.x += 0.01;
    }

    renderer.render(scene, camera);

    requestAnimationFrame(animate);
  }
  animate();

  return (
    <>
      <Helmet>
        <title>Let me peer inside</title>
      </Helmet>
      <div ref={myRef} />
    </>
  );
}
